<template>
  <div class="col-md-4">
    <div class="large-card">
      <div class="card-img h-100">
        <img
          :src="`/images/nfts/${modalData.template.template_id}.png`"
          class="img-fluid h-100"
          alt=""
          @click="stake(modalData.asset_id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HelperService from "../services/index";
export default {
  props: ["modalData"],
  methods: {
    getImage(hash) {
      return HelperService.getImgUrl(hash);
    },
    stake(id) {
      const data = {
        contract: "atomicassets",
        action: "transfer",
        data: {
          from: localStorage.getItem("wax_user"),
          to: process.env.VUE_APP_CONTRACT,
          asset_ids: [id],
          memo: `stake:cp`,
        },
      };
      HelperService.signTrans(data, (res) => {
        if (res.processed) {
          HelperService.successMsg("Transaction has been made,refreshing");
          setTimeout(() => {
            this.$emit("transaction-done", res.processed);
          }, 1000);
        }
      });
    },
  },
};
</script>
