<template>
  <!-- Modal -->
  <div class="modal-wrapper">
    <transition name="fade" appear mode="">
      <div class="modal-overlay" v-if="isShow"></div>
    </transition>

    <transition name="dialog" appear>
      <div
        class="modal show"
        v-if="isShow"
        id="modelId"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content px-3">
            <div class="modal-header text-center">
              <h4 class="modal-title text-center text-white d-inline-block w-100">
                Choose a card
              </h4>
              <button
                type="button"
                class="btn_close text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="$emit('close-modal')"
              >
                &times;
              </button>
            </div>
            <div class="modal-body">
              <section v-if="isLoading">
                <base-spinner> </base-spinner>
              </section>
              <span v-else>
                <div class="row" v-if="info">
                  <ModalListItem
                    :modalData="item"
                    v-for="(item, i) in info"
                    :key="i"
                    v-on:transaction-done="modalClose($event)"
                  />
                </div>
                <div class="row" v-else>No NFT Found to select</div>
              </span>
            </div>
            <footer class="modal-footer justify-content-center" v-if="!isLoading">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Select
              </button>
            </footer>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import HelperService from "../services/index";
import ModalListItem from "./ModalListItem.vue";
export default {
  props: ["isShow"],
  components: { ModalListItem },
  data() {
    return {
      info: null,
      apiRes: null,
      isLoading: false,
    };
  },
  mounted() {
    this.init("tools");
    this.info = this.apiRes;
  },

  methods: {
    init(params) {
      this.isLoading = true;
      HelperService.callApi(
        `collection_name=weedborncoun&page=1&limit=1000&schema_name=${params}`,
        async (response) => {
          let res = response["data"];
          if (res["success"]) {
            this.apiRes = res["data"].filter((i) => i.template.template_id == "584139");
            this.info = this.apiRes;
          } else {
            this.info = [];
          }
          this.isLoading = false;
        }
      );
    },
    modalClose(e) {
      this.$emit("transaction-done", e);
    },
  },
};
</script>

<style scoped>
.modal-content {
  background: #14192e;
  backdrop-filter: blur(10px);
}
.show {
  display: block;
}
.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}
.dialog-enter-active {
  transition: all 0.3s ease-out;
}
.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

.modal-overlay {
  content: "";
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background: #2c3e50;
  opacity: 0.6;
  cursor: pointer;
}
.modal-content {
  background: var(--primaryColor) !important;
  opacity: 0.9;
  border-radius: 46px !important;
  border: none !important;
  margin: auto;
}

.modal-header,
.modal-footer {
  border-top: 0;
  border-bottom: 0;
}
.btn_close {
  background: transparent;
  font-size: 50px;
  border: none;
}
/* ---------------------------------- */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
