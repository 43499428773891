<template>
  <div class="bg-img-1">
    <div class="h-100">
      <section class="box-lg">
        <div class="box-content">
          <h3 class="text-center">PRIVACY POLICY</h3>
          <p class="my-1 text-center text-white">
            This Privacy Policy describes how your personal information is collected,
            used, and shared when you visit from <a href="https://weedborncountygame.xyz/" target="_blank"
              >https://weedborncountygame.xyz</a
            > (the
            “Site”).
          </p>
          <p class="my-1 text-center text-white">
            Protecting your private information is our priority. This Statement of Privacy
            applies to <a href="https://weedborncountygame.xyz/" target="_blank"
              >https://weedborncountygame.xyz</a
            > and governs data collection and
            usage. We have developed this policy in order for you to understand how we
            collect, use, communicate and make use of personal information. The following
            outlines our privacy policy.
          </p>
          <p class="my-1 text-center text-white">
            Similar to other commercial websites, our website utilizes a standard
            technology called ‘cookies’ (see our cookies policy section for more
            information) and server logs to collect information about how our site is
            used. Information gathered through cookies and server logs may include the
            date and time of visits, the pages viewed, time spent at our site, and the
            websites visited just before and just after our own, as well as your IP
            address.
          </p>
          <h4 class="text-center">INTRODUCTION</h4>
          <p class="my-1 text-center text-white">
            This Policy sets out the conditions under which we may process any information
            that we collect from you, or that you provide to us. It covers information
            that could identify you (“personal information”) and information that could
            not. In the context of the law and this notice, “process” means collect,
            store, transfer, use or otherwise act on infor mation.
          </p>
          <p class="my-1 text-center text-white">
            1. We take seriously the protection of your privacy and confidentiality. We
            understand that all visitors to our website are entitled to know that their
            personal data will not be used for any purpose unintended by them, and will
            not accidentally fall into the hands of a third party.
          </p>
          <p class="my-1 text-center text-white">
            2. Weedborn County undertakes to preserve the confidentiality of all
            information you provide to us, and hope that you reciprocate.
          </p>
          <p class="my-1 text-center text-white">
            3. The law requires us to tell you about your rights and our obligations to
            you in regards to the processing and control of your personal data.
          </p>

          <h4 class="text-center">LOG FILES</h4>
          <p class="my-1 text-center text-white">
            Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) follows a standard
            procedure of using log files. These files, log visitors when they visit
            websites. All hosting companies do this and a part of hosting services'
            analytics. The information collected from log files includes internet protocol
            (IP) addresses, browser type, Internet Service Provider (ISP), date and time
            stamp, referring/exit pages, and possibly the number of clicks. These are not
            linked to any information that is personally identifiable. The purpose of the
            information is for analyzing trends, administering the site, tracking users'
            movement on the website, and gathering demographic information.
          </p>
          <h4 class="text-center">COLLECTION OF YOUR PERSONAL INFORMATION</h4>
          <p class="my-1 text-center text-white">
            Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) may collect personally
            identifiable information such as your: E-mail Address during token purchase.
          </p>
          <p class="my-1 text-center text-white">
            Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) encourages you to
            review the privacy statements of websites you choose to link to from
            <a href="https://weedborncountygame.xyz/" target="_blank"
              >https://weedborncountygame.xyz</a
            > so that you can understand how our website
            collect, use and share your information. We are not responsible for the
            privacy statements or other content on websites outside of the
            <a href="https://weedborncountygame.xyz/" target="_blank"
              >https://weedborncountygame.xyz</a
            > website.
          </p>

          <h4 class="text-center">WHAT INFORMATION WE COLLECT AND WHY?</h4>
          <p class="my-1 text-center text-white">We may collect information about you directly from you, as well as automatically through your use of our Site or Services.</p>
          <p class="my-1 text-center text-white">
            <ul class="text-center text-white">
              <li>Information We Collect Directly from You: Certain areas and features of our Site and Services require your email address, and IP address.</li>
              <li>Information We Collect Automatically: We may automatically collect the following information about your use of our Site via some software analytics including the length of time you visit our Site and your movement through our Site.</li>
              <li>We may also collect the following information: device ID; location and language information.</li>
            </ul>
          </p>
          <h4 class="text-center">OUR ADVERTISING PARTNERS</h4>
          <p class="my-1 text-center text-white">Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data.</p>
          <h5 class="text-center">INFORMATION WE PROCESS BECAUSE WE HAVE A CONTRACTUAL OBLIGATION WITH YOU</h5>
          <p class="my-1 text-center text-white">When you use our website, you agree to our terms and conditions, a contract is formed between you and us.</p>
          <p class="my-1 text-center text-white">In order to carry out our obligations under that contract we must process the information you give us. Some of this information may be personal information.</p>
          <p class="my-1 text-center text-white">We may use it in order to:</p>
          <p class="my-1 text-center text-white">
            <ul class="my-1 text-center text-white">
              <li>Verify your identity for security purposes</li>
              <li>Provide you with our services</li>
              <li>Provide you with suggestions and advice on services and how to obtain the most from using our website.</li>
            </ul>
          </p>
          <p class="my-1 text-center text-white">
            Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) may aggregate this information in a general way and use it to provide class information, for example to monitor our performance with respect to a particular service we provide. If we use it for this purpose, you as an individual will not be personally identifiable. Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) shall continue to process this information until the contract between us ends or is terminated by either party under the terms of the contract.
          </p>
          <p class="my-1 text-center text-white">Except where you have consented to our use of your information for a specific purpose, we do not use your information in any way that would identify you personally. Weedborn County may aggregate it in a general way and use it to provide class information, for example to monitor the performance of a particular page on our website.</p>
          <p class="my-1 text-center text-white">You may withdraw your consent at any time by instructing us (info@Blockchain Solution.info or Info@weedborncounty.io) However, if you do so, you may not be able to use our website or our services further</p>
          <h4 class="text-center">PERSONAL DATA STORAGE PERIOD OR CRITERIA FOR SUCH STORAGE</h4>
          <p class="my-1 text-center text-white">Your Personal data will be stored till:</p>
          <p class="my-1 text-center text-white">they are necessary to render you the Services;</p>
          <p class="my-1 text-center text-white">your consent is no longer valid;</p>
          <p class="my-1 text-center text-white">your personal data have been deleted following your data deletion request;</p>
          <p class="my-1 text-center text-white">we have received the court order or a lawful authority’s request mandating to permanently delete all the personal data we have obtained about you; or</p>
          <p class="my-1 text-center text-white">In other circumstances prescribed by applicable laws.</p>
          <p class="my-1 text-center text-white">In any event, we will not store your personal data for periods longer than it is necessary for the purposes of the processing.</p>
          <h4 class="text-center">GENERAL DATA PROTECTION REGULATION (GDPR)</h4>
          <p class="my-1 text-center text-white">We are a Data Controller of your information.</p>
          <p class="my-1 text-center text-white">Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:</p>
          <p class="my-1 text-center text-white">Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) needs to perform a contract with you</p>
          <p class="my-1 text-center text-white">You have given WBlockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) permission to do so</p>
          <p class="my-1 text-center text-white">Processing your personal information is in Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) legitimate interests</p>
          <p class="my-1 text-center text-white">Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) needs to comply with the law</p>
          <p class="my-1 text-center text-white">Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.</p>
          <p class="my-1 text-center text-white">If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information, we hold about you and if you want it to be removed from our systems, please contact us.</p>
          <p class="my-1 text-center text-white">You are entitled to the following rights under applicable laws:</p>
          <p class="my-1 text-center text-white">The right to access: you may at any time request to access your personal data. Upon request, we will provide a copy of your personal data in a commonly used electronic form.</p>
          <p class="my-1 text-center text-white">The right to rectification: you are entitled to obtain rectification of inaccurate personal data and to have incomplete personal data completed.</p>
          <p class="my-1 text-center text-white">The right to erase: under certain circumstances (including processing on the basis of your consent), you may request us to delete your User Data. Please note that this right is not unconditional. Therefore, an attempt to invoke the right might not lead to an action from us.</p>
          <p class="my-1 text-center text-white">The right to object: to certain processing activities conducted by the us in relation to your personal data, such as our processing of your personal data based on our legitimate interest. The right to object also applies to the processing of your personal data for direct marketing purposes.</p>
          <p class="my-1 text-center text-white">The right to data portability: you are entitled to receive your personal data (or have your personal data directly transmitted to another data controller) in a structured, commonly used and machine-readable format.</p>
          <h4 class="text-center">THIRD PARTY PRIVACY POLICIES</h4>
          <p class="my-1 text-center text-white">Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>
          <h4 class="text-center">CHILDREN'S INFORMATION</h4>
          <p class="my-1 text-center text-white">Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
          <p class="my-1 text-center text-white">Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
          <h4 class="text-center">ACCEPTABLE USE</h4>
          <p class="my-1 text-center text-white">You agree to use our website only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else’s use and enjoyment of the website. Prohibited behavior includes harassing or causing distress or inconvenience to any other user within our website. You must not use our website to send unsolicited commercial communications. You must not use the content on our website for any marketing related purpose without our express written consent.</p>
          <h4 class="text-center">THIRD PARTY ANALYTICS</h4>
          <p class="my-1 text-center text-white">We use automated devices and applications, to evaluate usage of our Site and, to the extent permitted, our Services. We also may use other analytic means to evaluate our Services. We use these tools to help us improve our Services, performance and user experiences. These entities may use tracking technologies to perform their services.</p>
          <h4 class="text-center">VERIFICATION OF YOUR INFORMATION</h4>
          <p class="my-1 text-center text-white">When we receive any request to access, edit or delete personal identifiable information, we shall first take reasonable steps to verify your identity before granting you access or otherwise taking any action. This is important to safeguard your information.</p>
          <h4 class="text-center">ENCRYPTION OF DATA SENT BETWEEN US</h4>
          <p class="my-1 text-center text-white">Our website use Secure Sockets Layer (SSL) certificates to verify our identity to your browser and to encrypt any data you give us. Whenever information is transferred between us, you can check that it is done so using SSL by looking for a closed padlock symbol or other trust mark in your browser’s URL bar or toolbar.</p>
          <h4 class="text-center">HOW YOU CAN COMPLAIN</h4>
          <p class="my-1 text-center text-white">If you are not happy with our privacy policy or if any complaints, then you should tell us by email. Our e-mail address is    info@Blockchain Solution.info or info@weedborncounty.io</p>
          <h4 class="text-center">REVIEW/MODIFICATION OF THIS PRIVACY POLICY</h4>
          <p class="my-1 text-center text-white">Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) may update this privacy notice from time to time as necessary. The terms that apply to you are those posted here on our website on the day you use our website. We solely advise you to print a copy for your records.</p>
          <p class="my-1 text-center text-white">If you have any question regarding our privacy policy, please contact us through:</p>
          <p class="my-1 text-center text-white">E-mail: info@Blockchain Solution.info or info@weedborncounty.io</p>
          <h4 class="text-center">COOKIES AND AUTOMATICALLY COLLECTED DATA</h4>
          <p class="my-1 text-center text-white">As you navigate through and interact with our website and the Services, we may ask your consent to use cookies, which are small files placed on the hard drive of your computer or mobile device, and web beacons, which are small electronic files located on pages of the website, to collect certain information about your equipment, browsing actions, and patterns.</p>
          <p class="my-1 text-center text-white">The data automatically collected from cookies and web beacons may include information from your web browser (such as browser type and browser language) and details of your visits to our website, including traffic data, location data and logs, page views, length of visit and website navigation paths as well as information about your device and internet connection, including your IP address and how you interact with the Services. We collect this data in order to help us improve our website and the Services.</p>
          <p class="my-1 text-center text-white">The information we collect automatically may also include statistical and performance information arising from your use of our Services and website. This type of data will only be used by us in an aggregated and anonymized manner.</p>
          <p class="my-1 text-center text-white">You can disable/delete the cookies set by our website - please find the appropriate instructions by following these links on how to implement the deletion in different browsers:</p>
          <p class="my-1 text-center text-white">For Google Chrome browser please refer to this instructions</p>
          <p class="my-1 text-center text-white">For Firefox browser please look up here</p>
          <p class="my-1 text-center text-white">For Safari browser please visit</p>
          <p class="my-1 text-center text-white">For Internet Explorer browser please refer to</p>
          <h4 class="text-center">SECURITY OF PROCESSING</h4>
          <p class="my-1 text-center text-white">We take information security very seriously. We work hard to protect the personal data you give us from loss, misuse, or unauthorized access. We utilize a variety of safeguards to protect the personal data submitted to us, both during transmission and once it is received.</p>
          <h4 class="text-center">PERSONAL IDENTIFIERS FROM YOUR BROWSING ACTIVITY</h4>
          <p class="my-1 text-center text-white">Information about your computer hardware and software may be automatically collected by Weedborn County. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the <a href="https://weedborncountygame.xyz/" target="_blank"
              >https://weedborncountygame.xyz</a
            > website.</p>
          <p class="my-1 text-center text-white">Requests by your web browser to our servers for web pages and other content on our website are recorded.</p>
          <p class="my-1 text-center text-white">Our website record information such as your geographical location, your Internet service provider and your IP address. We also record information about the software you are using to browse our website, such as the type of computer or device and the screen resolution.</p>
          <p class="my-1 text-center text-white">Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) uses this information in aggregate to assess the popularity of the webpages on our website and how we perform in providing content to you.</p>
          <h4 class="text-center">USE OF RE-MARKETING</h4>
          <p class="my-1 text-center text-white">Re-marketing involves placing a cookie on your computer when you browse our website in order to be able to serve to you an advert for our services when you visit some other website.</p>
          <p class="my-1 text-center text-white">Blockchain Solution di Pizzo Matteo Mario (VAT NUMBER: 02679240065) may use a third party to provide us with re-marketing services from time to time. If so, then if you have consented to our use of cookies, you may see advertisements for our services on other websites.</p>
          <h4 class="text-center">CONTACT INFORMATION</h4>
          <p class="my-1 text-center text-white">If you would like to: access, correct, register a complaint, or simply want more information please contact us directly Via:</p>
          <p class="my-1 text-center text-white">Contact: info@Blockchain Solution.info or info@weedborncounty.io</p>

        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
export default {
  name: "Policy",
  inheritAttrs: false,
  components: {
    Footer,
  },
  mounted() {
    localStorage.clear();
  },
};
</script>

<style>
.box-lg {
  width: 70%;
  display: flex;
  height: 800px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
  background: #000000a6;
  margin-top: 40px;
  border-radius: 6%;
  border: 4px solid #d7cd35;
  padding: 10px;
}
.box-content {
  align-items: center;
  justify-content: center;
  height: 700px;
  overflow: auto;
}
.weedpaper {
  margin: 20px;
}
</style>
<style scoped>
/* .bg-img-1 {
  height: 100%;
} */
.social-icon {
  margin-top: 15px;
}
.social-icon i {
  font-size: 20px;
}
.social-icon a {
  background: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-icon i:hover {
  color: #6fa4f3 !important;
  transition: color 0.4s;
}
.login-content {
  width: 50%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
}
.login-btn .btn {
  padding: 15px 60px;
  margin-top: 2rem;
  font-size: 20px !important;
}
</style>
