<template>
  <div class="grow-room">
    <div class="container text-center">
      <nav class="navbar navbar-expand-sm bg-transparent top-navbar">
        <div class="navbar-btn__container">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a href="#" class="btn" @click="decreaseFloor()">
                <img src="/images/arrow-left.png" alt="" class="left-arrow" />
              </a>
              <a href="/map" class="position-relative">
                <img src="/images/buttons.png" alt="" class="btn_img" />
                <h3 class="btn_title">Floor {{ count }}</h3>
              </a>
              <a here="#" class="btn" @click="increaseFloor()">
                <img src="/images/arrow-right.png" alt="" class="right-arrow" />
              </a>
            </li>
            <li class="nav-item">
              <a href="#" class="btn" @click="decreaseRoom()">
                <img src="/images/arrow-left.png" alt="" class="left-arrow" />
              </a>
              <a href="/map" class="position-relative">
                <img src="/images/buttons.png" alt="" class="btn_img" />
                <h3 class="btn_title">Room {{ selectRoom }}</h3>
              </a>
              <a here="#" class="btn" @click="increaseRoom()">
                <img src="/images/arrow-right.png" alt="" class="right-arrow" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div class="row room-block align-items-center px-5">
        <div class="col-md-4">
          <div class="card">
            <div
              class="
                card-body
                d-flex
                justify-content-center
                flex-column
                align-items-center
              "
            >
              <h3>Plot 0</h3>
              <button
                @click="pushToGrow(0)"
                class="btn room-btn btn-outline-light w-75"
              >
                Enter
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div
              class="
                card-body
                d-flex
                justify-content-center
                flex-column
                align-items-center
              "
            >
              <h3>Plot 1</h3>
              <button
                @click="pushToGrow(1)"
                class="btn room-btn btn-outline-light w-75"
              >
                Enter
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card">
            <div
              class="
                card-body
                d-flex
                justify-content-center
                flex-column
                align-items-center
              "
            >
              <h3>Plot 2</h3>
              <button
                @click="pushToGrow(2)"
                class="btn room-btn btn-outline-light w-75"
              >
                Enter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router/index";
export default {
  name: "Plot",
  data() {
    return {
      count: null,
      selectRoom: null,
    };
  },
  mounted() {
    this.count = this.$route.params.id;
    this.selectRoom = this.$route.params.room;
  },
  methods: {
    pushToGrow: function (plotId) {
      let floorId = this.$route.params.id;
      let roomid = this.$route.params.room;
      router.push(`/floor/${floorId}/${roomid}/${plotId}`);
    },
    increaseFloor() {
      if (this.$route.params.id <= 4) {
        let floorId = Number(this.$route.params.id) + 1;
        let roomid = Number(this.$route.params.room);
        router.push(`/floor/${floorId}/${roomid}`);
        this.count = floorId;
        // console.log(this.count);
      }
    },
    decreaseFloor() {
      if (this.$route.params.id > 1) {
        let floorId = Number(this.$route.params.id) - 1;
        let roomid = Number(this.$route.params.room);
        // console.log("minus", floorId);
        router.push(`/floor/${floorId}/${roomid}`);
        this.count = floorId;
      }
    },
    increaseRoom() {
      // alert("increase");
      if (this.$route.params.room <= 2) {
        let floorId = Number(this.$route.params.id);
        let roomid = Number(this.$route.params.room) + 1;
        router.push(`/floor/${floorId}/${roomid}`);
        this.selectRoom = roomid;
        // console.log(this.count);
      }
    },
    decreaseRoom() {
      // alert("decrease");
      if (this.$route.params.room > 1) {
        let floorId = Number(this.$route.params.id);
        let roomid = Number(this.$route.params.room) - 1;
        // console.log("minus", floorId);
        router.push(`/floor/${floorId}/${roomid}`);
        this.selectRoom = roomid;
      }
      // console.log(this.count);
    },
  },
};
</script>

<style scoped>
.navbar-btn__container {
  /* margin-left: 20%; */
  margin-top: 6px;
  width: 100%;
}

.navbar-nav {
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 1;
}

.room-block {
  margin-top: 12%;
}
.top-navbar {
  width: 75%;
  margin: 1rem auto;
}
</style>
