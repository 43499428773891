<template>
  <div class="shop-bg">
    <div class="container">
      <div class="shop-wrapper">
        <div class="shop-title d-inline-block">
          <h1 class="d-inline-block">Shop</h1>
        </div>
        <div class="row">
          <div class="col-md-3">
            <section class="filter-wrapper">
              <!-- <h2 class="title">Your balance : {{ balance }}</h2> -->
              <button
                class="btn bg_secondary text-capitalize float-end btn-filter"
                @click="openFiltersList = !openFiltersList"
              >
                filters
              </button>
              <div class="hideList" :class="openFiltersList ? 'showList' : ''">
                <div class="list-wrapper">
                  <label for="#" class="filter-title ms-2 my-2">Schema</label>
                  <ul class="schema-list">
                    <li>
                      <a
                        href="#"
                        :class="`${searchTerm == 'tools' ? 'selected' : ''}`"
                        @click="filter('tools')"
                        >tools</a
                      >
                    </li>
                    <li>
                      <a
                        href="#"
                        :class="`${searchTerm == 'seeds' ? 'selected' : ''}`"
                        @click="filter('seeds')"
                        >seeds</a
                      >
                    </li>
                    <li>
                      <a
                        href="#"
                        :class="`${searchTerm == 'heroes' ? 'selected' : ''}`"
                        @click="filter('heroes')"
                        >heroes</a
                      >
                    </li>
                    <li>
                      <a
                        href="#"
                        :class="`${searchTerm == 'packs' ? 'selected' : ''}`"
                        @click="filter('packs')"
                        >packs</a
                      >
                    </li>
                    <li>
                      <a
                        href="#"
                        :class="`${searchTerm == 'pass' ? 'selected' : ''}`"
                        @click="filter('pass')"
                        >pass</a
                      >
                    </li>
                    <li>
                      <a
                        href="#"
                        :class="`${searchTerm == 'buildings' ? 'selected' : ''}`"
                        @click="filter('buildings')"
                        >buildings</a
                      >
                    </li>
                  </ul>
                </div>

                <div class="from-group position-relative">
                  <label for="name" class="ms-2 my-2 filter-title">Rarity</label>

                  <select
                    class="form-control input-filter"
                    name="rarity"
                    @change="search()"
                    v-model="rarity"
                  >
                    <option value="all">All</option>
                    <option value="Common">Common</option>
                    <option value="Rare">Rare</option>
                    <option value="Epic">Epic</option>
                  </select>
                  <i class="fa fa-caret-down caret-down" aria-hidden="true"></i>
                </div>
              </div>
            </section>
          </div>
          <div class="col-md-9">
            <section class="main-content-wrapper">
              <loading v-model:active="isLoading" :can-cancel="true" />
              <div class="row" v-if="!isLoading">
                <div class="col-md-6 col-lg-3" v-for="(item, index) in info" :key="index">
                  <div class="market-card">
                    <header class="card_header">
                      <!-- <p class="card-id">#090234</p> -->
                      <div class="card-pricing">
                        <span class="text-white"
                          ><img
                            src="/images/wax-logo.png"
                            alt="wax"
                            class="img-fluid wax-logo"
                          />
                          <span class="mx-2 card_price">{{ item.price }}</span>
                        </span>
                      </div>
                      <button
                        class="info_btn"
                        @click="cardInfo(selectedInfo[index], index)"
                      >
                        <i class="fa fa-info" aria-hidden="true"></i>
                      </button>
                    </header>

                    <section class="card-info" v-if="selectedInfo[index]">
                      <ul class="p-0">
                        <li>
                          title <span>{{ item.asset.name }}</span>
                        </li>
                        <li>
                          rarity
                          <span>{{ item.asset.immutable_data.Rarity }}</span>
                        </li>
                        <li>
                          schema <span>{{ item.schema }}</span>
                        </li>
                        <li>
                          template <span>{{ item.asset.template_id }}</span>
                        </li>
                        <li>
                          burnable
                          <span v-if="item.asset.is_burnable == true">Yes</span>
                          <span v-else>No</span>
                        </li>
                        <li>
                          Transferable
                          <span v-if="item.asset.is_transferable == true">Yes</span>
                          <span v-else>No</span>
                        </li>
                        <!-- <li>Max supply <span>0</span></li> -->
                        <!-- <li>Sale <span>80000</span></li> -->
                        <li>
                          Price <span>{{ item.asset_t }}</span>
                        </li>
                      </ul>
                    </section>

                    <section class="card_img" v-else>
                      <!-- <ImgBlock :img="item.asset.immutable_data.img" /> -->
                      <ImgBlock
                        :img="`/images/nfts/${item.asset_t}.png`"
                        :defaultLink="getImgUrl(item.asset.immutable_data.img)"
                        :local="true"
                      />
                    </section>

                    <section
                      class="card-content fw-bold text-center"
                      :class="selectedInfo === 'card-info-list' ? 'hideContent' : ''"
                    >
                      <h3 class="card_title">{{ item.asset.name }}</h3>
                      Supply {{ item.supply }} / Left
                      {{ Number(item.supply) - Number(item.total_sale) }}
                    </section>
                    <footer class="card_footer mt-3">
                      <div class="form-group mb-2">
                        <input
                          type="number"
                          class="form-control"
                          placeholder="Quantity"
                          v-model="qty[index]"
                        />
                      </div>

                      <button
                        @click="buy(item.asset_t, item.price, index)"
                        class="btn btn-buy"
                      >
                        buy
                      </button>
                    </footer>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
import ImgBlock from "../components/ImgBlock.vue";
export default {
  name: "shop",
  components: {
    Loading,
    ImgBlock,
  },
  computed: {
    ...mapGetters(["balance"]),
  },
  data() {
    return {
      selectedInfo: [],
      openFiltersList: false,
      isLoading: true,
      waxLoaded: false,
      searchTerm: "",
      apiRes: null,
      rarity: "all",
      qty: [],
    };
  },
  mounted() {
    ApiService.market((res) => {
      this.info = res;
      this.apiRes = res;
      this.$store.commit("addMarket", this.info);
      this.isLoading = false;
      console.log(this.balance);
    });
  },
  methods: {
    async buy(templateId, price, index) {
      let priceSym = price.split(/(\d+)/);
      priceSym[1] = priceSym[1] * this.qty[index];
      let finalPrice = priceSym.join("");
      if (templateId && price && this.qty[index] > 0) {
        let currencyWax = price.includes("WAX");
        let currencyWbc = price.includes("WBC");
        let currencyWbx = price.includes("WBX");
        let data = {
          contract: "",
          action: "transfer",
          data: {
            memo: `buy:${templateId}:${this.qty[index]}`,
            from: localStorage.getItem("wax_user"),
            to: process.env.VUE_APP_CONTRACT,
            quantity: finalPrice,
          },
        };
        if (currencyWax) {
          data.contract = process.env.VUE_APP_WAX_CONTRACT;
        } else if (currencyWbx) {
          data.contract = process.env.VUE_APP_WBX_CONTRACT;
        } else if (currencyWbc) {
          data.contract = process.env.VUE_APP_WBC_CONTRACT;
        }
        await ApiService.signTrans(data, (response) => {
          if (response.processed) {
            ApiService.successMsg("Purchase Successfull");
          }
        });
      } else {
        ApiService.errorMsg("Quantity must be greater than 0.");
      }
    },
    cardInfo(tab, index) {
      this.selectedInfo[index] = !tab;
    },
    getImgUrl(hash) {
      let url = "http://ipfs.io/ipfs/" + hash;
      return "//images.weserv.nl/?url=" + url + "&w=500";
    },
    filter(term) {
      this.searchTerm = term;
      this.info = this.apiRes.filter((el) => el.schema == term);
    },
    search() {
      this.isLoading = true;
      if (this.rarity == "all") {
        this.info = this.apiRes;
      } else {
        this.info = this.apiRes.filter(
          (el) => el.asset.immutable_data.Rarity == this.rarity
        );
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
  },
};
</script>
<style scoped>
.shop-wrapper {
  margin-top: 12%;
}

.title {
  font-size: 25px;
  margin-top: 10px;
  font-weight: 600;
}

.shop-title {
  font-weight: bold;
  text-transform: uppercase;
}

.info_btn:hover,
i {
  border-color: #d9dbe2;
  transition: all 0.4s;
}
.hideContent {
  display: none;
}

.btn-filter {
  float: right;
  color: #fff !important;
  position: relative;
  display: none;
}
.card_footer .btn-buy {
  display: block;
}
.main-content-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: 600px;
  padding-right: 10px;
  padding-bottom: 70px;
}
.schema-list .selected {
  background: #01253d;
}
</style>
