<template>
  <div class="userFloorMain shadow-layer grow-room">
    <div class="row">
      <div class="d-flex justify-content-center">
        <div class="col-12 col-md-3">
          <div class="card">
            <div class="card-body card_inner">
              <h5 class="card-title">Floor 1</h5>
              <!-- <a href="#" class="card-link">Card link</a> -->
              <div class="slot_leftMain">
                <router-link to="/floor/1" class="position-relative btn_hover slot_left">
                  <img src="/images/buttons.png" alt="" class="btn_img" />
                  <h3 class="btn_title">Floor 1</h3>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="card">
            <div class="card-body card_inner">
              <h5 class="card-title">Floor 2</h5>
              <div class="slot_leftMain">
                <router-link
                  to="/floor/2"
                  class="position-relative btn_hover slot_left"
                  v-if="is_floor_unlocked"
                >
                  <img src="/images/buttons.png" alt="" class="btn_img" />
                  <h3 class="btn_title">Floor 2</h3>
                </router-link>
                <a
                  href="#"
                  class="position-relative btn_hover slot_left"
                  @click="unlock()"
                  v-else
                >
                  <img src="/images/buttons.png" alt="" class="btn_img" />
                  <h3 class="btn_title">Unlock</h3>
                </a>
                <p>
                  <a
                    href="#"
                    v-if="is_floor_unlocked"
                    class="text-sm-bold"
                    @click="lock()"
                    >Unstake Floor NFT</a
                  >
                </p>
                <h6 v-if="!is_floor_unlocked">Requires Floor NFT</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "../services";
export default {
  name: "UserFloor",
  data() {
    return {
      info: null,
      loading: true,
      is_floor_unlocked: false,
      stake_floor_id: 0,
      stakes: [],
    };
  },
  created() {
    this.init();
    ApiService.callApi(
      `collection_name=weedborncoun&schema_name=buildings`,
      async (response) => {
        let res = response["data"];
        if (res["success"] && res.data.length) {
          this.floor_nft = res.data[0].asset_id;
        }
        this.isLoading = false;
      }
    );
  },
  methods: {
    init() {
      ApiService.getTableScope(
        "floor2",
        null,
        null,
        (response) => {
          if (response) {
            this.stakes = response[0].details.length ? response[0].details[0].value : [];
            this.is_floor_unlocked = response[0].floor_nft ? true : false;
            this.stake_floor_id = response[0].floor_nft;
            this.loading = false;
          } else {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          ApiService.errorMsg(ApiService.errorFormat(error));
        }
      );
    },
    async lock() {
      if (this.stake_floor_id) {
        ApiService.transaction(
          "unstake.flr",
          { account: localStorage.getItem("wax_user"), floor_id: this.stake_floor_id },
          (res) => {
            if (res.processed) {
              ApiService.successMsg("Transaction has been made.");
              this.init();
            }
          },
          (error) => {
            ApiService.errorMsg(ApiService.errorFormat(error));
          }
        );
      }
    },
    async unlock() {
      if (this.floor_nft) {
        ApiService.plant(
          {
            from: localStorage.getItem("wax_user"),
            to: process.env.VUE_APP_CONTRACT,
            asset_ids: [this.floor_nft],
            memo: "unlocked:floor",
          },
          (res) => {
            if (res.processed) {
              ApiService.successMsg("Transaction completed successfully.");
              this.init();
            }
          },
          (error) => {
            ApiService.errorMsg(ApiService.errorFormat(error));
          }
        );
      } else {
        ApiService.errorMsg("No floor NFT found in your wallet.");
      }
    },
  },
};
</script>
<style>
.userFloorMain {
  padding-top: 130px;
  overflow-x: hidden;
}
.card_inner {
  height: 230px;
  text-align: center;
  padding-top: 70px !important;
}
.slot_left .btn_img {
  width: 80px;
}
.slot_left h3 {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 4px;
  font-size: 12px;
}
</style>
