<template>
  <div class="bg-img-2">
    <div class="container text-center">
      <loading v-model:active="isLoading" :is-full-page="true" />
      <div class="waitingBlock bgColor" v-if="!isLoading">
        <div
          class="row no-gutters align-item-center justify-content-center"
          v-if="info.length > 0"
        >
          <div class="col-md-3" v-for="(item, i) in info" :key="i">
            <div class="card">
              <div class="card-body mb-0 pb-0">
                <vue-load-image>
                  <template v-slot:image>
                    <img class="card-img-top" :src="getImgUrl(item.data.img)" alt="" />
                  </template>
                  <template v-slot:preloader>
                    <img class="card-img-top px-3 pt-3 pb-0" src="/images/loader.gif" />
                  </template>
                  <template v-slot:error>Image load fails</template>
                </vue-load-image>
              </div>
              <div class="homeButtons mt-10">
                <a
                  href="#"
                  class="position-relative"
                  @click.prevent="claim(item.asset_id)"
                >
                  <img src="images/buttons.png" alt="" class="homeButtons_img" />
                  <h3 class="homeButtons_title">Harvest</h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../services";
import { useToast } from "vue-toastification";
const toast = useToast();
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
import VueLoadImage from "vue-load-image";
export default {
  name: "Waiting",
  components: {
    Loading,
    "vue-load-image": VueLoadImage,
  },
  computed: {
    ...mapGetters(["waitData"]),
  },
  data() {
    return {
      info: null,
      isLoading: true,
    };
  },
  mounted() {
    this.isLoading = true;
    ApiService.getPlantStatus(async (res) => {
      this.info = res;
      for (let index = 0; index < this.info.length; index++) {
        const asset = this.info[index];
        await ApiService.getAssetById(
          asset.seed_asset_id,
          (row) => {
            this.info[index] = row.data.data;
            if (this.info.length == index + 1) {
              this.isLoading = false;
            }
          },
          null
        );
      }
    });
  },
  methods: {
    getImgUrl(hash) {
      return ApiService.getImgUrl(hash);
    },
    async claim(seed) {
      console.log(seed);
      if (seed) {
        const data = {
          seed_id: seed,
          owner: localStorage.getItem("wax_user"),
        };
        await ApiService.transaction(
          "harvest",
          data,
          (res) => {
            if (res.processed) {
              toast.success("Claimed...");
              this.$router.push("growhouse");
            }
          },
          (error) => {
            this.error = ApiService.errorFormat(error);
            ApiService.errorMsg(this.error);
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.select-btn {
  text-align: center;
}
.card {
  background: none !important;
}
</style>
