<template>
  <div class="bg-img-1">
    <div class="login h-100">
      <section class="login-content">
        <img src="/images/logo-1.png" class="img-login" alt="" />
        <div class="rpc-server-wrapper mt-5">
          <RpcServer />
        </div>
        <div class="login-btn">
          <button
            type="button"
            class="btn btn-all pulse"
            @click="handle_login()"
          >
            Login
          </button>
        </div>

        <div class="social-icon">
          <p class="my-1 text-center text-white">Follow us</p>
          <div class="d-flex">
            <a href="https://twitter.com/WeedbornCounty" target="_blank"
              ><i class="fab fa-twitter"></i>
            </a>
            <a href="https://discord.gg/t27SH4UwPt" target="_blank"
              ><i class="fab fa-discord"></i
            ></a>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>
<script>
import RpcServer from "../components/RpcServer.vue";
import Footer from "../components/Footer.vue";
import LoginService from "../services/LoginService";
export default {
  components: {
    RpcServer,
    Footer,
  },
  name: "Login",
  data() {
    return {
      ual: null,
      showPopup: false,
    };
  },
  inheritAttrs: false,
  mounted() {
    localStorage.removeItem("ual-session-authenticator");
    localStorage.removeItem("ual-session-expiration");
    localStorage.removeItem("autoLogin");
    localStorage.removeItem("loglevel:webpack-dev-server");
    localStorage.removeItem("ual-wax:autologin");
    localStorage.removeItem("wax_user");
    localStorage.clear();
    const loginService = new LoginService();
    this.ual = loginService.handle_login();
    this.ual.init();
  },
  methods: {
    handle_login() {
      var [buttonUAL] = document.body.getElementsByClassName("ual-button-gen");
      if (this.ual) {
        this.ual.init();
        buttonUAL.click();
        let elements = document.body.getElementsByClassName("ual-button-gen");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      } else {
        const loginService = new LoginService();
        this.ual = loginService.handle_login();
        this.ual.init();
        buttonUAL.click();
        let elements = document.body.getElementsByClassName("ual-button-gen");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },
  },
};
</script>

<style>
#ual-modal {
  /* z-index: 1000; */
  background: transparent !important;
}
.img-login {
  width: 430px;
}
#ual-modal-selection-content {
  position: relative;
}
.ual-modal-close {
  position: absolute;
  top: 11px;
  right: 17px;
}
#ual-button {
  display: none !important;
}
.ual-auth-text {
  display: inline-block !important;
  padding: 20px 4px 17px 10px !important;
  font-size: 10px !important;
  width: 137px !important;
}
.fa-chevron-right {
  font-size: 16px !important;
  float: right;
  margin: 12px 6px;
}
.weedpaper {
  margin: 20px;
}
#ual-modal-selection-content {
  margin-top: 10px !important;
}
.ual-auth-icon-wrapper {
  padding: 12px 10px 8px 10px !important;
  overflow: hidden;
}
#ual-modal #ual-modal-selection-content {
  font-family: "Source Sans Pro", sans-serif;
  background-color: rgba(220, 233, 233, 0.9) !important;
  border: 1px solid transparent !important;

  /* border-radius: 6px;
  width: 642px; */
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<style scoped>
/* .bg-img-1 {
  height: 100%;
} */
.social-icon {
  margin-top: 15px;
}
.social-icon i {
  font-size: 20px;
}
.social-icon a {
  background: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-icon i:hover {
  color: #6fa4f3 !important;
  transition: color 0.4s;
}
.login-content {
  width: 50%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
}
.login-btn .btn {
  padding: 15px 60px;
  margin-top: 2rem;
  font-size: 20px !important;
}

@media (max-width: 430px) {
  .login-content {
    width: 80%;
    height: 100%;
  }
  .inner-text {
    width: 190px;
  }
  .img-login {
    width: 100%;
  }
  .login-btn .btn {
    padding: 14px 56px;
    font-size: 15px !important;
  }
}
</style>
