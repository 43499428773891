import { createStore } from "vuex";
import ApiService from '../services/'
import { useToast } from "vue-toastification";
const toast = useToast();
export default createStore({
    state: {
        tokenPrice: 0,
        balance: 0,
        username: null,
        selectedSeed: null,
        selectedCard: null,
        selectedControlPanel: null,
        selectedExtractor: null,
        inventory: null,
        selectedPot: null,
        userStakes: { asset_ids: [] },
        selectedLamp: null,
        selectedHero: null,
        selectedPump: null,
        myAssets: null,
        stakes: null,
        seeds: null,
        floor2Seeds: null,
        playerXp: null,
    },
    actions: {
        getMyAssets: function({ commit }, params) {
            ApiService.getAssets(params, (rows) => {
                if (rows) {
                    commit("setMyAssets", rows);
                }
            })
        },
        getPlayerXp: function({ commit }) {
            ApiService.rpc("playerxps", (rows) => {
                if (rows) {
                    commit("setXP", rows[0]);
                }
            }, (error) => ApiService.errorMsg(ApiService.errorFormat(error)), 1, localStorage.getItem("wax_user"), localStorage.getItem("wax_user"));
        },
        getUserStakes: function({ commit }) {
            ApiService.rpc("hero.stake", (rows) => {
                if (rows) {
                    commit("addUserStakes", rows[0]);
                }
            }, (error) => toast.error(ApiService.errorFormat(error)));
        },
        getUserAvatar: function({ commit }, user) {
            ApiService.userPhoto(user, (res) => {
                if (res.rows.length) {
                    commit("updateDailyWinnerAvatar", { account: user, image: res.rows[0].photo_hash });
                }
            });
        },
        getTempateImage: function({ commit }, params) {
            ApiService.getByTempleteId(params.reward, (res) => {
                if (res.data.data) {
                    commit("updateRewardTemplate", { account: params.account, image: res.data.data.immutable_data.img });
                }
            });
        },
        getSeeds: function({ commit }) {
            ApiService.callApi(
                `collection_name=weedborncoun&schema_name=test`,
                async(response) => {
                    let res = response["data"];
                    if (res["success"] && res.data.length > 0) {
                        let seeds = res.data.filter((row) => row.data.Type == "Seed");
                        commit("seedStore", seeds);
                        // let seed = seeds[0].data;
                        // seed.asset_id = seeds[0].asset_id;
                        // commit("setSeed", seed);
                    }
                }
            );
        }
    },
    mutations: {
        setMyAssets: function(state, rows) {
            state.myAssets = rows
        },
        addUserStakes: function(state, rows) {
            state.userStakes = rows
        },
        setXP: function(state, rows) {
            state.playerXp = rows
        },
        setSeed: function(state, row) {
            state.selectedSeed = row
        },
        setFloor2Seeds: function(state, row) {
            state.floor2Seeds = row
        },
        setHero: function(state, row) {
            state.selectedHero = row
        },
        seedStore: function(state, row) {
            state.seeds = row
        },
        setPump: function(state, row) {
            state.selectedPump = row
        },
        setPot: function(state, row) {
            state.selectedPot = row
        },
        setLamp: function(state, row) {
            state.selectedLamp = row
        },
        setAC: function(state, row) {
            state.selectedExtractor = row
        },
        setCP: function(state, row) {
            state.selectedControlPanel = row
        },
        addBalance(state, val) {
            if (val) {
                val = parseFloat(val.toString());
                state.balance = val
            }
        },
        addUser(state, val) {
            state.username = Number(val)
        },
        addTool(state, val) {
            if (val.data.Type.includes("Lamp")) {
                state.selectedLamp = val.data;
                state.selectedLamp.asset_id = val.asset_id;
                state.selectedLamp.asset_t = val.template.template_id;
            } else if (val.data.Type.includes("Hero")) {
                state.selectedHero = val.data;
                state.selectedHero.asset_id = val.asset_id;
                state.selectedHero.asset_t = val.template.template_id;
            } else if (val.data.Type.includes("Pot")) {
                state.selectedPot = val.data;
                state.selectedPot.asset_id = val.asset_id;
                state.selectedPot.asset_t = val.template.template_id;
            } else if (val.data.Type.includes("Pump")) {
                state.selectedPump = val.data;
                state.selectedPump.asset_id = val.asset_id;
                state.selectedPump.asset_t = val.template.template_id;
            } else if (val.data.Type.includes("Seed")) {
                // state.selectedSeed = val.data;
                state.selectedSeed = val.asset_id;
            } else if (val.data.Type.includes("Extractor")) {
                state.selectedExtractor = val.data;
                state.selectedExtractor.asset_id = val.asset_id;
                state.selectedExtractor.asset_t = val.template.template_id;
            } else if (val.data.Type.includes("Control Panel")) {
                state.selectedControlPanel = val.data;
                state.selectedControlPanel.asset_id = val.asset_id;
                state.selectedControlPanel.asset_t = val.template.template_id;
            }
        },
        addCard(state, val) {
            state.selectedCard = val.data;
            state.selectedCard.asset_id = val.asset_id;
            state.selectedCard.asset_t = val.template.template_id;
            if (val.data.Type.includes("Hero")) {
                state.selectedHero =val.data;
                state.selectedHero.asset_id = val.asset_id;
                state.selectedHero.asset_t = val.template.template_id;
            } else if (val.data.Type.includes("Lamp")) {
                state.selectedLamp = val.data;
                state.selectedLamp.asset_id = val.asset_id;
                state.selectedLamp.asset_t = val.template.template_id;
            } else if (val.data.Type.includes("Pot")) {
                state.selectedPot = val.data;
                state.selectedPot.asset_id = val.asset_id;
                state.selectedPot.asset_t = val.template.template_id;
            } else if (val.data.Type.includes("Pump")) {
                state.selectedPump = val.data;
                state.selectedPump.asset_id = val.asset_id;
                state.selectedPump.asset_t = val.template.template_id;
            } else if (val.data.Type.includes("Seed")) {
                state.selectedSeed = val.data;
                state.selectedSeed.asset_id = val.asset_id;
                state.selectedSeed.asset_t = val.template.template_id;
            } else if (val.data.Type.includes("Extractor")) {
                state.selectedExtractor = val.data;
                state.selectedExtractor.asset_id = val.asset_id;
                state.selectedExtractor.asset_t = val.template.template_id;
            } else if (val.data.Type.includes("Control Panel")) {
                state.selectedControlPanel = val.data;
                state.selectedControlPanel.asset_id = val.asset_id;
                state.selectedControlPanel.asset_t = val.template.template_id;
            }
        },
        addCardDefault(state, val) {
            if (val.pot_asset_id != 0) {
                state.selectedPot = { img: "pot" };
            }
            if (val.pump_asset_id != 0) {
                state.selectedPump = { img: "pump" };
            }
            if (val.lamp_asset_id != 0) {
                state.selectedLamp = { img: "lamp" };
            }
        },
        addInventory(state, val) {
            state.inventory = val;
        },
        updateBalance(state, val) {
            state.balance = Number(state.balance) + Number(val)
        },
        decrementBalance(state, val) {
            state.balance = Number(state.balance) - Number(val)
        },
        addMarket(state, val) {
            state.market = val
        },
    },
    getters: {
        balance: state => {
            if (state.balance) {
                let numberVal = state.balance
                return new Intl.NumberFormat().format(numberVal)
            }
            return 0;
        },
        myAssets: state => state.myAssets,
        username: state => state.username,
        selectedSeed: state => state.selectedSeed,
        selectedCard: state => state.selectedCard,
        selectedPot: state => state.selectedPot,
        selectedLamp: state => state.selectedLamp,
        selectedHero: state => state.selectedHero,
        selectedPump: state => state.selectedPump,
        selectedExtractor: state => state.selectedExtractor,
        selectedControlPanel: state => state.selectedControlPanel,
        inventory: state => state.inventory,
        userStakes: state => state.userStakes,
        floor2Seeds: state => state.floor2Seeds,
    }
});