<template>
  <div class="bg-img-1">
    <section class="home-content">
      <div class="container">
        <div class="w-50 mx-lg-auto top-btn-group">
          <div class="row text-center justify-content-center">
            <div class="col-md-4">
              <div class="btn-container__unknown d-lg-inline-block d-none mt-5">
                <router-link to="#" class="btn pulse btn-all">?</router-link>
              </div>
            </div>
            <div class="col-md-4">
              <div class="btn-container__control-panel d-inline-block">
                <router-link to="/control-panel" class="btn pulse btn-all"
                  >Control center</router-link
                >
              </div>
            </div>
            <div class="col-md-4">
              <div class="btn-container__unknown d-lg-inline-block d-none mt-5">
                <router-link to="#" class="btn pulse btn-all">?</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-container mt-4">
          <div
            class="btn-container_enterBtn d-lg-inline-block d-block mb-4 mb-lg-0 mobile_viewMenu"
          >
            <router-link to="/floor" class="btn pulse btn-all">Grow House</router-link>
          </div>

          <div
            class="btn-container__club d-lg-inline-block d-block mb-4 mb-lg-0 mobile_viewMenu"
          >
            <router-link to="/club" class="btn pulse btn-all">The club</router-link>
          </div>

          <div class="btn-container__lap mb-4 mb-lg-0 mobile_viewMenu">
            <router-link to="/lab" class="btn pulse btn-all">Chem Lab</router-link>
          </div>

          <div
            class="btn-container__mission d-lg-inline-block d-block mb-4 mb-lg-0 mobile_viewMenu"
          >
            <router-link to="/task-mission" class="btn pulse btn-all"
              >Task & mission</router-link
            >
          </div>

          <div
            class="btn-container__uni d-lg-inline-block d-block mb-4 mb-lg-0 mobile_viewMenu"
          >
            <router-link to="/university" class="btn pulse btn-all"
              >University</router-link
            >
          </div>

          <div
            class="btn-containe_lockBtn d-lg-inline-block d-block mb-4 mb-lg-0 mobile_viewMenu"
          >
            <router-link to="/shop" class="btn pulse btn-all">Shop</router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// <a href="https://codnocrats.com">Codnocrats</a>
import router from "../router";
import { mapState } from "vuex";
export default {
  name: "home",
  computed: {
    ...mapState(["playerXp"]),
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    myFun: function () {
      router.push("/floor");
    },
  },
};
</script>

<style scoped>
.btn-containe_lockBtn {
  position: absolute;
  top: 48%;
  right: 7%;
}
.btn-container__uni {
  position: absolute;
  top: 45%;
  right: 28%;
}
.btn-container__club {
  position: absolute;
  top: 36%;
  left: 43%;
}
.btn-container__mission {
  position: absolute;
  bottom: 20%;
  left: 41%;
}
.top-btn-group {
  position: absolute;
  left: 34%;
  top: 20%;
  margin: auto;
}
</style>
