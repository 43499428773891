<template>
  <div class="task-mission">
    <div class="container task-mission-content">
      <div class="row h-100">
        <div class="col-12 col-lg-6 mb-5 mb-lg-0 mb-md-0">
          <div class="section_main">
            <div class="section_header">
              <h5>Task</h5>
            </div>
            <div class="scroll-view">
              <div class="row" v-if="!loading">
                <div
                  class="col-12 col-lg-4 col-md-6"
                  v-for="(item, index) in info"
                  :key="index"
                >
                  <TaskCard
                    :row="item"
                    :userTasks="item.users_details"
                    v-on:transaction-done="init()"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="section_main border_left">
            <div class="section_header">
              <h5>Mission</h5>
            </div>
            <div class="mission_section">
              <h2>Coming Soon</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelperService from "../services/index";
import TaskCard from "../components/TaskCard";
export default {
  name: "TaskMission",
  components: { TaskCard },
  data() {
    return {
      isOpen: false,
      loading: true,
      info: null,
      userTasks: null,
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    selectOption() {
      this.isOpen = !this.isOpen;
    },
    closeModal() {
      this.selectOption();
      this.init();
    },
    getImage(hash) {
      return HelperService.getImgUrl(hash);
    },
    init() {
      this.loading = true;
      HelperService.getTable("tasks", null, (response) => {
        if (response) {
          this.info = response;
        }
        this.loading = false;
      });
    },
    join(type) {
      const data = {
        contract: process.env.VUE_APP_CONTRACT,
        action: "task.init",
        data: {
          account: localStorage.getItem("wax_user"),
          type: type,
        },
      };
      HelperService.signTrans(data, (res) => {
        if (res.processed) {
          HelperService.successMsg("Transaction has been made,refreshing");
          setTimeout(() => {
            this.$emit("transaction-done", res.processed);
          }, 2000);
        }
      });
    },
  },
};
</script>

<style scoped>
.task-mission-content {
  margin-top: 9%;
  background: rgba(0, 0, 0, 0.5);
  /*padding: 40px 10px 10px;*/
  border-radius: 15px;
  height: 85%;
}
.section_main {
  padding: 15px 10px 30px;
  height: 100%;
}
.slot_left h3 {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 0px;
  font-size: 14px;
}
.slot_left .btn_img {
  width: 75px;
}
.task_cart p {
  font-size: 12px;
}
.mission_section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.border_left {
  border-left: 2px solid #ffffff;
}
.scroll-view {
  height: 65%;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 10px 120px 0;
}
@media (min-width: 1000px) {
  .flip-clock__piece {
    width: 50px;
  }
}
</style>
