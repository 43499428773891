<template>
  <section
    class="miner-slot bg-blackish my-lg-4 position-relative nft_section_main"
    v-if="!loading"
  >
    <div class="slot_leftMain">
      <a href="#" class="position-relative btn_hover slot_left">
        <img src="/images/buttons.png" alt="" class="btn_img" />
        <h3 class="btn_title">Room {{ count + 1 }}</h3>
      </a>
    </div>
    <div class="miner-inner-content">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <ul class="mine-card p-0 d-flex nft_cards">
            <li class="selected_image">
              <!-- <img class="img-fluid" :src="getImage('Pump')" alt="card image" /> -->
              <ImgBlock :local="true" :img="getImage('Pump')" />
              <div class="mine-card__body pt-1 text-center">
                <h5 class="card-title my-2">Pump</h5>
              </div>
            </li>
            <li class="selected_image">
              <ImgBlock :local="true" :img="getImage('Lamp')" />
              <div class="mine-card__body pt-1 text-center">
                <h5 class="card-title my-2">Lamp</h5>
              </div>
            </li>
            <li class="selected_image">
              <ImgBlock :local="true" :img="getImage('Pot')" />
              <div class="mine-card__body pt-1 text-center">
                <h5 class="card-title my-2">Pot</h5>
              </div>
            </li>
            <li class="selected_image">
              <ImgBlock :local="true" :img="getImage('Seed')" />
              <div class="mine-card__body pt-1 text-center">
                <h5 class="card-title my-2">Seed</h5>
              </div>
            </li>
            <li class="selected_image" v-if="mineRow.hero">
              <ImgBlock :local="true" :img="heroImg" />
              <div class="mine-card__body pt-1 text-center">
                <h5 class="card-title my-2">Hero</h5>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-lg-6">
          <div class="mine-card text-center">
            <div class="mine-card__body pt-1">
              <span class="card-title my-2">
                <Countdown
                  :deadline="timeLeft"
                  countdownSize="1.6rem"
                  labelSize="0.6rem"
                  :showDays="true"
                  :showLabels="true"
                  :mainColor="`#fff`"
                  :labelColor="`#fff`"
                  :flipAnimation="true"
                />
              </span>
              <p class="text-success">{{ mineRow.reward || 0 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="slot_leftMain right_slot">
      <a
        href="#"
        class="position-relative btn_hover slot_left"
        @click="harvest(mineRow.seed_id)"
        v-if="expired"
      >
        <img src="/images/buttons.png" alt="" class="btn_img" />
        <h3 class="btn_title">Harvest</h3>
      </a>
      <a
        href="#"
        class="position-relative btn_hover slot_left"
        @click="unstake(mineRow.seed_id)"
      >
        <img src="/images/buttons.png" alt="" class="btn_img" />
        <h3 class="btn_title">Unstack</h3>
      </a>
    </div>
  </section>
</template>

<script>
import HelperService from "../services/index";
import moment from "moment";
import { Countdown } from "vue3-flip-countdown";
import ImgBlock from "../components/ImgBlock";
export default {
  name: "FloorHouseCard",
  props: ["mineRow", "count", "openRoomsCount"],
  components: { Countdown, ImgBlock },
  data() {
    return {
      isOpen: false,
      loading: true,
      timeLeft: null,
      apiRes: null,
      heroImg: null,
    };
  },
  created() {
    this.getTimeLeft(this.mineRow);
    HelperService.openApiCall(
      `ids=${this.mineRow.seed_id},${this.mineRow.pot_id},${this.mineRow.lamp_id},${this.mineRow.pump_id}`,
      (res) => {
        this.apiRes = res.data;
        this.getImageByTemplate();
        this.loading = false;
      }
    );
  },
  methods: {
    getTimeLeft(stake) {
      let cUTC = moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let endDate = moment(stake.last_claimed)
        .add(stake.growth_time / 60, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      this.expired = moment(cUTC).isAfter(moment(endDate));
      return (this.timeLeft = moment.utc(endDate).local().format("YYYY-MM-DD HH:mm:ss"));
    },
    claimable(item) {
      let cUTC = moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let endDate = moment(item.last_claimed).format("YYYY-MM-DD HH:mm:ss");
      const claimable = moment(cUTC).isAfter(moment(endDate));
      return claimable;
    },
    selectOption() {
      this.isOpen = !this.isOpen;
    },
    getImage(type) {
      let item = this.apiRes.filter((row) => {
        if ("Type" in row.data && row.data.Type.includes(type)) {
          return row;
        }
      });
      if (item.length) {
        return "/images/nfts/" + item[0].template.template_id + ".png";
      } else {
        return `/images/${type.toLowerCase()}_common.png`;
      }
    },
    getImageByTemplate() {
      if (this.mineRow.hero) {
        HelperService.openApiCall(`ids=${this.mineRow.hero}`, (res) => {
          this.heroImg = "/images/nfts/" + res.data[0].template.template_id + ".png";
          this.loading = false;
        });
      }
    },
    unstake(id) {
      const data = {
        contract: process.env.VUE_APP_CONTRACT,
        action: "unplant2",
        data: {
          account: localStorage.getItem("wax_user"),
          seed_id: id,
        },
      };
      HelperService.signTrans(data, (res) => {
        if (res.processed) {
          HelperService.successMsg("Transaction has been made,refreshing");
          setTimeout(() => {
            this.$emit("transaction-done", res.processed);
          }, 1000);
        }
      });
    },
    harvest(id) {
      const data = {
        contract: process.env.VUE_APP_CONTRACT,
        action: "hvest",
        data: {
          account: localStorage.getItem("wax_user"),
          seed_id: id,
        },
      };
      HelperService.signTrans(data, (res) => {
        if (res.processed) {
          HelperService.successMsg("Transaction has been made,refreshing");
          setTimeout(() => {
            this.$emit("transaction-done", res.processed);
          }, 2000);
        }
      });
    },
  },
};
</script>

<style scoped>
.selected_image img {
  max-height: 165px;
  width: 125px;
}
.mine-text {
  padding: 0 12px;
}
.slot_leftMain {
  position: absolute;
  top: 40%;
  left: 4%;
  font-size: 1.2rem;
}
.slot_left .btn_img {
  width: 80px;
}
.slot_left h3 {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 4px;
  font-size: 12px;
}
.mine-button {
  position: absolute;
  top: 43%;
  right: 4%;
}
.miner-inner-content {
  width: 65%;
  margin: auto;
}
.miner-wrapper {
  margin-top: 10%;
}
.right_slot {
  left: inherit;
  right: 4%;
}
.large-card .card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 120px;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  width: 100px;
  border: 3px solid #fff;
  border-radius: 15px;
  margin: auto;
}
.selected_image .card-title {
  font-size: 14px;
}
.miner-slot {
  background-color: rgb(0 0 0 / 80%);
}
</style>
