<template>
  <nav class="navbar navbar-expand-lg main-navbar">
    <div class="container-fluid">
      <div class="navbar-brand home-btn d-flex ms-2 ms-lg-0 ms-md-0">
        <router-link
          to="/map"
          class="position-relative btn_hover"
          v-show="$route.meta.sideBarKey != 'map'"
        >
          <img src="/images/home.png" alt="" class="home_img img-fluid" />
        </router-link>
        <a
          href="javascript:void(0)"
          @click="goBack"
          class="position-relative plotBtn ms-5"
          :class="$route.meta.sideBarKey == 'plot' ? 'plotActive' : ''"
        >
          <img src="/images/buttons.png" alt="" class="btn_img" />
          <h3 class="btn_title">Back</h3>
        </a>
      </div>
      <div class="d-block d-lg-none mobileSpeakerIcon">
        <button class="sound_btn">
          <span v-if="played" @click="playSound(0)">
            <i class="fas fa-volume-up"></i>
          </span>
          <span v-else @click="playSound(1)">
            <i class="fas fa-volume-mute"></i>
          </span>
        </button>
      </div>
      <button
        class="navbar-toggler d-lg-none"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavId"
        aria-controls="collapsibleNavId"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="mobileShow = !mobileShow"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        :class="mobileShow ? 'show' : ''"
        id="collapsibleNavId"
      >
        <div class="position-relative right_dropdown_main">
          <ul class="navbar-nav ms-auto mt-2 mt-lg-0">
            <li
              class="nav-item mt-lg-0 me-3 text-white fw-bold d-lg-flex d-none align-items-center"
            >
              <div>
                <button class="sound_btn">
                  <span v-if="played" @click="playSound(0)">
                    <i class="fas fa-volume-up"></i>
                  </span>
                  <span v-else @click="playSound(1)">
                    <i class="fas fa-volume-mute"></i>
                  </span>
                </button>
              </div>
            </li>
            <li class="nav-item mt-lg-0 me-3 text-white fw-bold bg-label">
              WAX <br />
              {{ parseFloat(waxBalance) }}
            </li>
            <li class="nav-item mt-lg-0 me-3 mb-2 mb-lg-0 text-white fw-bold bg-label">
              WBX <br />
              {{ parseFloat(wbx) }}
            </li>
            <li class="nav-item mt-lg-0 me-3 mb-2 mb-lg-0 text-white fw-bold bg-label">
              WBC <br />
              {{ parseFloat(wbc) }}
            </li>
            <li class="nav-item mt-lg-3 me-3 mb-2 mb-lg-0 mobile_userName">
              <a class="d-inline-block text-white fw-bold" href="javascript:void(0)">
                {{ authentication.user_name }}
              </a>
            </li>
            <li class="nav-item dropdown mobile_menu">
              <a
                class="d-inline-block btn_hover"
                href="javascript:void(0)"
                @click="isShow = !isShow"
              >
                <img
                  src="/images/Senzatitolo-14.png"
                  alt="Menu Image"
                  class="img-fluid home_img d-none d-lg-block"
                />
                <!-- {{ authentication.user_name }} -->
                <!-- <i class="fa fa-angle-down text-dark mt-2" aria-hidden="true"></i> -->
              </a>
              <div class="dropdown-menu" :class="isShow ? 'show' : ''">
                <router-link to="/floor" class="dropdown-item" @click="isShow = false"
                  >Grow house</router-link
                >
                <router-link
                  to="/control-panel"
                  class="dropdown-item"
                  @click="isShow = false"
                  >Control Center</router-link
                >
                <router-link to="/lab" class="dropdown-item" @click="isShow = false"
                  >Chem lab</router-link
                >
                <router-link to="/club" class="dropdown-item" @click="isShow = false"
                  >The club</router-link
                >
                <router-link to="/shop" class="dropdown-item" @click="isShow = false"
                  >Shop</router-link
                >
                <router-link to="#" @click.prevent="showRvcModal" class="dropdown-item"
                  >RPC Server</router-link
                >
                <router-link
                  to="/university"
                  class="dropdown-item"
                  @click="isShow = false"
                  >University</router-link
                >
                <router-link
                  to="/task-mission"
                  class="dropdown-item"
                  @click="isShow = false"
                  >Task & mission</router-link
                >
                <router-link to="/login" @click.prevent="logout" class="dropdown-item"
                  >logout</router-link
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <RpcModal :isShow="showModal" v-if="showModal" v-on:hide-modal="showModal = false" />
</template>

<script>
import { reactive } from "vue";
import RpcModal from "../components/RpcModal.vue";
import ApiService from "../services";
var audio = new Audio("/audio/game_bg.mp3");
export default {
  name: "Navbar",
  components: {
    RpcModal,
  },
  data() {
    return {
      played: false,
      isShow: false,
      showModal: false,
      mobileShow: false,
      wbxLoaded: false,
      wbcLoaded: false,
      waxBalance: 0,
      wbc: 0,
      wbx: 0,
    };
  },
  setup() {
    const authentication = reactive({
      user_name: localStorage.getItem("wax_user"),
    });
    return {
      authentication,
    };
  },
  mounted() {
    ApiService.getBalance(process.env.VUE_APP_WBX_CONTRACT, "WBX", (res) => {
      if (res.data.length > 0) {
        this.wbx = res.data[0];
      }
      this.wbxLoaded = true;
    });
    ApiService.getBalance(process.env.VUE_APP_WBC_CONTRACT, "WBC", (res) => {
      if (res.data.length > 0) {
        this.wbc = res.data[0];
      }
      this.wbcLoaded = true;
    });
    ApiService.getAccount((res) => {
      if (res.data) {
        this.$store.commit("addBalance", res.data.core_liquid_balance);
        this.waxBalance = res.data.core_liquid_balance;
      }
      this.waxLoaded = true;
    });
  },
  methods: {
    playSound(start) {
      if (start) {
        // alert("hiii");
        audio.play();
        audio.loop = true;
      } else {
        audio.pause();
      }
      this.played = start;
    },
    showRvcModal() {
      this.showModal = true;
      this.isShow = false;
    },
    logout() {
      this.$router.push("/login");
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.navbar {
  position: absolute;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.navbar .container-fluid {
  padding: 0 100px;
}

.plotBtn {
  display: none;
}
.plotActive {
  display: block !important;
}

.navbar .homeBtnActive {
  display: none;
}
.home-btn .btn_title {
  top: 11px;
}
.dropdown-menu {
  min-width: 100%;
}
.dropdown-menu .dropdown-item:hover {
  background: transparent !important;
  color: rgb(145, 134, 134);
  transition: color 0.4s;
}
.home_img {
  width: 95px;
}
.sound_btn {
  background: transparent;
  border: 0px;
}
.sound_btn span {
  font-size: 24px;
}
.navbar-collapse {
  flex-grow: unset;
}
</style>
