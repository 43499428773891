<template>
  <div class="grow-room d-flex">
    <div class="container text-center">
      <nav class="navbar navbar-expand-sm mb-5 bg-transparent top-navbar">
        <div class="navbar-btn__container">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a href="#" class="btn">
                <img
                  src="/images/arrow-left.png"
                  alt=""
                  class="left-arrow"
                  @click="decreaseFloor()"
                />
              </a>
              <a href="/floor" class="position-relative">
                <img src="/images/buttons.png" alt="" class="btn_img" />
                <h3 class="btn_title">Floor {{ count }}</h3>
              </a>
              <a here="#" class="btn">
                <img
                  src="/images/arrow-right.png"
                  alt=""
                  class="right-arrow"
                  @click="increaseFloor()"
                />
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div
        class="row room-block align-items-center px-5 justify-content-center"
      >
        <div class="col-md-3 col-lg-4 col-md-6 col-12">
          <div class="card">
            <div
              class="
                card-body
                d-flex
                justify-content-center
                flex-column
                align-items-center
              "
            >
              <h3>Room 1</h3>
              <button
                @click="pushToGrow(1)"
                class="btn room-btn btn-outline-light w-75"
              >
                Enter
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-lg-4 col-md-6 col-12">
          <div class="card">
            <div
              class="
                card-body
                d-flex
                justify-content-center
                flex-column
                align-items-center
              "
            >
              <h3>Room 2</h3>
              <button
                @click="pushToGrow(2)"
                class="btn room-btn btn-outline-light w-75"
              >
                Enter
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-3 col-lg-4 col-md-6 col-12">
          <div class="card">
            <div
              class="
                card-body
                d-flex
                justify-content-center
                flex-column
                align-items-center
              "
            >
              <h3>Room 3</h3>
              <button
                @click="pushToGrow(3)"
                class="btn room-btn btn-outline-light w-75"
              >
                Enter
              </button>
            </div>
          </div>
        </div>

        <MakeStake
          :error="error"
          v-if="showModal"
          :cards="info"
          :cardName="card"
          v-on:w-modal-hide-click="showModal = false"
        />
      </div>
      >
    </div>
  </div>
</template>

<script>
import router from "../router/index";
import MakeStake from "../components/MakeStake";
export default {
  name: "Room",
  data() {
    return {
      count: null,
      showModal: false,
      floor: "",
      toolstakes: null,
      card: null,
    };
  },
  components: {
    MakeStake,
  },
  mounted() {
    this.count = this.$route.params.id;
    this.floor = this.$route.params.id;
  },
  methods: {
    showSelection(option) {
      this.card = option;
      this.showModal = true;
    },
    pushToGrow: function (roomid) {
      router.push(`/floor/${this.floor}/${roomid}`);
    },
    increaseFloor() {
      if (this.$route.params.id <= 4) {
        this.floor = Number(this.floor) + 1;
        router.push(`/floor/${this.floor}`);
        this.count = this.floor;
      }
    },
    decreaseFloor() {
      if (this.$route.params.id > 1) {
        this.floor = Number(this.floor) - 1;
        router.push(`/floor/${this.floor}`);
        this.count = this.floor;
      }
      // console.log(this.count);
    },
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: center;
}
.navbar-nav {
  position: relative;
  z-index: 1;
}
.navbar-nav .nav-item {
  margin-left: 0 !important;
}
.navbar-btn__container {
  margin-top: 6px;
}

.room-block {
  margin-top: 12%;
}
.top-navbar {
  width: 50%;
  margin: 1rem auto;
}
</style>
