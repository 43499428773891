<template>
  <div class="container">
    <loading v-model:active="isLoading" />
    <transition name="modal fade">
      <div class="modal-mask modal-backdrop">
        <div class="modal-wrapper w-100 px-3 px-lg-0 px-md-0">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <h1 class="tm5 modal-title d-inline-block">Choose Your Hero</h1>
              <a
                href="JavaScript:void(0)"
                class="btn-cross text-right close-modal"
                @click="hideModal($event)"
                >&times;</a
              >
              <div class="modal-body mdScroll px-5 pb-5">
                <div class="text-center">
                  <div class="row no-gutters" v-if="!isLoading">
                    <div class="col-md-4 mb-3" v-for="(item, index) in info" :key="index">
                      <div class="card" :class="item.selected ? 'selected' : ''">
                        <div class="card-body p-0">
                          <img
                            class="card-img-top"
                            :src="`/images/nfts/${item.template.template_id}.png`"
                            alt=""
                            @click.prevent="addCard(item)"
                            @click="isSelected = !isSelected"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ApiService from "../services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "StakeModal",
  components: {
    Loading,
  },
  props: ["showModel", "schema"],
  showModal: true,
  data() {
    return {
      info: null,
      isLoading: false,
      isImageLoaded: false,
      apiRes: null,
      isSelected: false,
    };
  },
  mounted() {
    this.init(this.schema);
  },
  methods: {
    hideModal(e) {
      this.$emit("transaction-done", e);
    },
    loaded() {
      this.isImageLoaded = true;
    },
    getImgUrl(hash) {
      let url = "http://ipfs.io/ipfs/" + hash;
      return "//images.weserv.nl/?url=" + url + "&w=400";
    },
    addCard(item) {
      const data = {
        contract: "atomicassets",
        action: "transfer",
        data: {
          from: localStorage.getItem("wax_user"),
          to: process.env.VUE_APP_CONTRACT,
          asset_ids: [item.asset_id],
          memo: `stake:hero`,
        },
      };
      ApiService.signTrans(data, (res) => {
        if (res.processed) {
          ApiService.successMsg("Transaction has been made,refreshing");
          setTimeout(() => {
            this.$emit("transaction-done", res.processed);
          }, 1000);
        }
      });
    },
    init(schema) {
      console.log(this.$route, this.$router);
      this.isLoading = true;
      ApiService.callApi(
        `collection_name=weedborncoun&page=1&limit=1000&schema_name=${schema}`,
        async (response) => {
          let res = response["data"];
          if (res["success"]) {
            this.apiRes = res["data"];
            this.info = res["data"];
          } else {
            this.info = [];
          }
          this.isLoading = false;
        }
      );
    },
  },
};
</script>
<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-y: hidden; */
}
.modal-title {
  margin-bottom: 0;
  text-align: center;
  font-size: 27px;
}
.modal-backdrop .modal-wrapper .modal-content .card {
  height: 290px !important;
}
.modal-wrapper .modal-content {
  background: var(--primaryColor) !important;
  opacity: 0.9;
  border-radius: 46px !important;
  border: none !important;
  margin: auto;
}

.mdScroll {
  overflow-y: scroll;
  height: 413px;
}
.mdScroll::-webkit-scrollbar {
  width: 4px;
}

.mdScroll::-webkit-scrollbar-thumb {
  background: transparent;
  height: 2px !important;
  border-radius: 20px;
}

.mdScroll:hover::-webkit-scrollbar-thumb {
  background: #766640;
}

.mdScroll::-webkit-scrollbar-thumb:hover {
  background: #7b61ff;
}

.mdScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-right: none;
  border-left: none;
}

.mdScroll::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 22px;
}

.mdScroll::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 28px;
}

.close-modal {
  font-size: 28px;
  position: absolute;
  top: -18px;
  right: 24px;
  font-size: 45px;
  color: #e9c8a8;
}
.modal-wrapper .modal-content .modal-body .card {
  width: 100% !important;
  border: 1px solid #fff !important;
  margin-bottom: 20px;
}
.modal-wrapper .modal-content .modal-body .btn {
  background: #fff;
  color: black;
  border-radius: 27px;
  font-weight: bold;
  font-size: 2rem;
  width: 150px;
  margin: 10px auto;
}
.modal-wrapper .modal-content .card:hover {
  box-shadow: 10px 15px 20px rgba(209, 203, 203, 0.6);
  transform: translateY(-8px);
  transition: all 0.3;
}
.modal-wrapper .modal-content .card.selected {
  border: 2px solid red !important;
}
</style>
<style scoped>
.progress-cont .progress__bar {
  height: 9px !important;
}
</style>
