<template>
  <section class="control-panel">
    <div class="container">
      <section class="card-wrapper">
        <div class="row justify-content-center align-item-center" v-if="!loading">
          <div class="col-md-4" v-for="(item, i) in stakes" :key="i">
            <CardListItem :stake="item" :index="i" v-on:transaction-done="init()" />
          </div>
          <div
            class="col-12 col-lg-4 col-md-4"
            v-for="index in stakes ? 2 - stakes.length : 2"
            :key="index"
          >
            <div class="large-card" @click="selectOption">
              <a href="JavaScript:void(0)" class="card-img">
                <i class="fa fa-plus fa-3x" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <SelectionDialog
        v-if="isOpen"
        :isShow="isOpen"
        v-on:transaction-done="closeModal()"
      />
    </div>
  </section>
</template>

<script>
import HelperService from "../services/index";
import moment from "moment";
import CardListItem from "../components/CardListItem.vue";
import SelectionDialog from "../components/SelectionDialog.vue";
export default {
  name: "StakePage",
  components: { CardListItem, SelectionDialog },
  data() {
    return {
      isOpen: false,
      loading: true,
      stakes: null,
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    getTimeLeft(stake) {
      let cUTC = moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let endDate = moment(stake.last_claimed)
        .add(4, "hours")
        .format("YYYY-MM-DD HH:mm:ss");
      this.expired = moment(cUTC).isAfter(moment(endDate));
      return moment.utc(endDate).local().format("YYYY-MM-DD HH:mm:ss");
    },
    doesExpired(item) {
      let cUTC = moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let endDate = moment(item.last_claimed).format("YYYY-MM-DD HH:mm:ss");
      return moment(cUTC).isAfter(moment(endDate));
    },
    selectOption() {
      this.isOpen = !this.isOpen;
    },
    closeModal() {
      this.selectOption();
      this.init();
    },
    getImage(hash) {
      return HelperService.getImgUrl(hash);
    },
    init() {
      HelperService.getTable("cp.stake", null, (response) => {
        if (response) {
          this.stakes = response.filter(
            (item) => item.owner == localStorage.getItem("wax_user")
          );
          this.stakes = this.stakes.length > 0 ? this.stakes : null;
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.card-wrapper {
  width: 80%;
  /* border: 1px solid; */
  margin: 8rem auto;
  height: 100%;
  /* padding: 30px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.35) !important;
  backdrop-filter: blur(10px); */
}

.large-card .card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 280px;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  /*padding: 26px 10px;*/
  width: 220px;
  border: 3px solid #fff;
  border-radius: 15px;
  margin: auto;
}

.button-wrapper a {
  color: brown !important;
}

.button-wrapper {
  width: 80%;
  margin: auto;
}

.button-wrapper .btn {
  width: 100%;
}

.btn {
  border: 0;
  border-radius: 7px;
  /* width: 100%; */
  text-align: center;
  padding: 3px 25px;
  margin-top: 5px;
  font-weight: bold;
  background: aqua;
  transition: all 0.4s;
}

.btn:hover {
  background: rgb(2, 135, 135);
}
</style>
