import {
    createRouter,
    createWebHistory
} from 'vue-router';
import Home from '../views/Home.vue';
import Policy from '../views/Policy.vue';
import Terms from '../views/Terms.vue';
import CopyRights from '../views/CopyRights.vue';
import Imprint from '../views/Imprint.vue';
import Login from '../views/Login.vue';
import GrowHouse from '../views/GrowHouse';
import Stake from '../views/Stake';
import Waiting from '../views/Waiting';
import Floor from '../views/Floor';
import FloorTwo from '../views/FloorTwo';
import UserFloor from '../views/UserFloor';
import InGame from '../views/InGame'
import Shop from '../views/Shop'
import ControlPanel from '../views/ControlPanel'
import University from '../views/University'
import TheClub from '../views/TheClub'
import TaskMission from '../views/TaskAndMission'
import Room from '../components/Room';
import Plot from '../components/Plot';
import ApiService from '../services';
import mainView from '../components/Main';
import LoginLayout from '../components/LoginLayout';
const routes = [{
        path: '/',
        redirect: '/map',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/map',
            name: 'map',
            props: true,
            component: Home,
            meta: {
                sideBarKey: 'map',
            },
        }, ],
    },
    {
        path: '/growhouse',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/growhouse',
            name: 'growhouse',
            component: GrowHouse,
            meta: {
                sideBarKey: 'growhouse',
            },
        }, ],
    },
    {
        path: '/in-game',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/in-game',
            name: 'in-game',
            component: InGame,
            meta: {
                sideBarKey: 'in-game',
            },
        }, ],
    },
    {
        path: '/shop',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/shop',
            name: 'shop',
            component: Shop,
            meta: {
                sideBarKey: 'shop',
            },
        }, ],
    },
    {
        path: '/control-panel',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/control-panel',
            name: 'control-panel',
            component: ControlPanel,
            meta: {
                sideBarKey: 'control-panel',
            },
        }, ],
    },
    {
        path: '/university',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/university',
            name: 'university',
            component: University,
            meta: {
                sideBarKey: 'university',
            },
        }, ],
    },
    {
        path: '/task-mission',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/task-mission',
            name: 'task-mission',
            component: TaskMission,
            meta: {
                sideBarKey: 'task-mission',
            },
        }, ],
    },
    {
        path: '/lab',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/lab',
            name: 'lab',
            component: Stake,
            meta: {
                sideBarKey: 'lab',
            },
        }, ],
    },
    {
        path: '/club',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/club',
            name: 'club',
            component: TheClub,
            meta: {
                sideBarKey: 'club',
            },
        }, ],
    },
    {
        path: '/waiting',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '/waiting',
            name: 'waiting',
            component: Waiting,
            meta: {
                sideBarKey: 'waiting',
            },
        }, ],
    },
    {
        path: '/floor/1',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login');
            } else {
                next();
            }
        },
        children: [{
            path: '',
            name: 'floor1',
            component: Floor,
            meta: {
                sideBarKey: 'floor1',
            },
        },{
            path: ':id',
            name: 'floorroom',
            component: Room,
        }, {
            path: ':id/:room',
            component: Plot,
            name: 'room',
        }, {
            path: ':id/:room/:plot',
            component: GrowHouse,
            name: 'plot',
            meta: {
                sideBarKey: 'plot',
            },
        }, ],
    },
    {
        path: '/floor/2',
        component: mainView,
        children: [{
            path: '/floor/2',
            name: 'floor2',
            component: FloorTwo,
            meta: {
                sideBarKey: 'floor2',
            },
        }, ],
    },
    {
        path: '/floor',
        component: mainView,
        children: [{
            path: '/floor',
            name: 'floor',
            component: UserFloor,
            meta: {
                sideBarKey: 'floor',
            },
        }, ],
    },
    {
        path: '/login',
        component: LoginLayout,
        children: [{
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                sideBarKey: 'login',
            },
        }, ],
    },
    {
        path: '/imprint',
        component: Imprint,
        children: [{
            path: '/imprint',
            name: 'imprint',
            component: Imprint,
            meta: {
                sideBarKey: 'imprint',
            },
        }, ],
    },
    {
        path: '/copy-rights',
        component: CopyRights,
        children: [{
            path: '/copy-rights,',
            name: 'copyRights,',
            component: CopyRights,
            meta: {
                sideBarKey: 'copyRights,',
            },
        }, ],
    },
    {
        path: '/terms',
        component: Terms,
        children: [{
            path: '/terms,',
            name: 'terms,',
            component: Terms,
            meta: {
                sideBarKey: 'terms,',
            },
        }, ],
    },
    {
        path: '/policy',
        component: Policy,
        children: [{
            path: '/policy,',
            name: 'policy,',
            component: Policy,
            meta: {
                sideBarKey: 'policy,',
            },
        }, ],
    },
];
const isLogin = function () {
    return ApiService.isLogin();
};
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
export default router;