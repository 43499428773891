<template>
  <div class="bg-img-1">
    <div class="h-100">
      <section class="box-lg">
        <div class="box-content">
          <h3 class="text-center">TERMS & CONDITIONS</h3>
          <p class="my-1 text-center text-white">INTRODUCTION</p>
          <p class="my-1 text-center text-white">
            These are the terms and conditions governing the use of
            <a href="https://weedborncountygame.xyz/" target="_blank"
              >https://weedborncountygame.xyz</a
            >, (the Website) and the agreement that operates between us and you (the
            Terms). The Website is owned and operated by BSItaly di Pizzo Matteo Mario
            (VAT NUMBER: 02679240065) (us/our/we) and these Terms set out the rights and
            obligations of all users (you/your) in relation to your use of the Website.
          </p>
          <p class="my-1 text-center text-white">
            Please read carefully these Terms and our Privacy Statement. By using the
            Website, you are consenting to be bound by the current Terms and our Privacy
            Statement. We may revise the Terms and information contained on the Website at
            any time and without notice. If you do not agree to these Terms or the Privacy
            Statement, please refrain from using the Website.
          </p>
          <p class="my-1 text-center text-white">
            If you have any questions about the Terms or the Privacy Statement, please
            contact us through mail info@bsitaly.info or info@weedborncounty.io.
          </p>
          <h4 class="text-center">CONDUCT OF USE</h4>
          <p class="my-1 text-center text-white">
            You are not allowed to use this website in any way that is unlawful, abusive,
            threatening, harassing, obscene, libellous, hateful, or in any other way
            violating these terms and conditions.
          </p>
          <h4 class="text-center">TRANSLATIONS</h4>
          <p class="my-1 text-center text-white">
            The Website may contain translations of the English version of the content
            available on the Website. These translations are provided only as a
            convenience. In the event of any conflict between the English language version
            and the translated version, the English language version shall take
            precedence. If you notice any inconsistency, please report them to
            info@bsitaly.info or info@weedborncounty.io.
          </p>
          <h4 class="text-center">ELIGIBILITY</h4>
          <p class="my-1 text-center text-white">
            In order to use the Interface, you must satisfy the following eligibility
            requirements. You hereby represent and warrant, to and for the benefit of us
            and each of our officers, directors, supervisors, shareholders, members,
            investors, employees, agents, service providers and affiliates that you
            satisfy all of the eligibility requirements as of each date that you make any
            use or receive any benefits of the Interface.
          </p>
          <p class="my-1 text-center text-white">
            you are of legal age in the jurisdiction in which you reside and you have
            legal capacity to enter into the Terms and be bound by them;
          </p>
          <p class="my-1 text-center text-white">
            if you accept the Terms on behalf of a legal entity, you must have the legal
            authority to accept the Terms on that entity’s behalf, in which case “you” as
            used herein (except as used in this paragraph) will mean that entity;
          </p>
          <p class="my-1 text-center text-white">you are not a Restricted Person; and</p>
          <p class="my-1 text-center text-white">
            your use of the Interface is not prohibited by and does not otherwise violate
            or facilitate the violation of any applicable laws or regulations, or
            contribute to or facilitate any illegal activity.
          </p>
          <h4 class="text-center">ACCESS TO THE INTERFACE</h4>
          <p class="my-1 text-center text-white">
            We reserve the right to disable access to the Interface at any time, with or
            without cause or good reason. Our grounds for terminating access to the
            Interface may include, but are not limited to, any breach of the Terms,
            including without limitation, if we, in our sole discretion, believe that you,
            at any time, fail to satisfy the eligibility requirements set forth in the
            Terms. Further, we reserve the right to limit or restrict access to the
            Interface by any person or entity, or within any geographic area or legal
            jurisdiction, at any time and in our sole discretion. We will not be liable to
            you for any losses or damages you may suffer as a result of or in connection
            with the Interface being inaccessible to you at any time or for any reason.
          </p>
          <h4 class="text-center">COMPLIANCE WITH TAX OBLIGATIONS</h4>
          <p class="my-1 text-center text-white">
            The users of the Website are solely responsible to determinate what, if any,
            taxes apply to their Weedborn County transactions. The owners of, or
            contributors to, the Website are NOT responsible for determining the taxes
            that apply to Weedborn County transactions.
          </p>
          <h4 class="text-center">PROHIBITED ACTIVITY</h4>
          <p class="my-1 text-center text-white">
            You agree not to engage in, or attempt to engage in, any of the following
            categories of prohibited activity in relation to your access or use of the
            Interface:
          </p>
          <p class="my-1 text-center text-white">Activity that breaches the Terms;</p>
          <p class="my-1 text-center text-white">
            Activity that infringes on or violates any copyright, trademark, service mark,
            patent, right of publicity, right of privacy, or other proprietary or
            intellectual property rights under the law.
          </p>
          <p class="my-1 text-center text-white">
            Activity that seeks to interfere with or compromise the integrity, security,
            or proper functioning of any computer, server, network, personal device, or
            other information technology system, including, but not limited to, the
            deployment of viruses and denial of service attacks.
          </p>
          <p class="my-1 text-center text-white">
            Activity that seeks to defraud us or any other person or entity, including,
            but not limited to, providing any false, inaccurate, or misleading information
            in order to unlawfully obtain the property of another.
          </p>
          <p class="my-1 text-center text-white">
            Activity that violates any applicable law, rule, or regulation concerning the
            integrity of trading markets, including, but not limited to, the manipulative
            tactics commonly known as spoofing and wash trading.
          </p>
          <p class="my-1 text-center text-white">
            Activity that violates any applicable law, rule, or regulation of any Major
            Jurisdiction.
          </p>
          <p class="my-1 text-center text-white">
            Activity that disguises or interferes in any way with the IP address of the
            computer you are using to access or use the Interface or that otherwise
            prevents us from correctly identifying the IP address of the computer you are
            using to access the Interface.
          </p>
          <p class="my-1 text-center text-white">
            Activity that transmits, exchanges, or is otherwise supported by the direct or
            indirect proceeds of criminal or fraudulent activity.
          </p>
          <p class="my-1 text-center text-white">
            Activity that contributes to or facilitates any of the foregoing activities.
          </p>
          <h4 class="text-center">NO PROFESSIONAL ADVICE OR FIDUCIARY DUTIES</h4>
          <p class="my-1 text-center text-white">
            All information provided in connection with your access and use of the
            Interface is for informational purposes only and should not be construed as
            professional advice. You should not take, or refrain from taking, any action
            based on any information contained in the Interface or any other information
            that we make available at any time, including, without limitation, blog posts,
            articles, links to third-party content, news feeds, tutorials, tweets and
            videos. Before you make any financial, legal, or other decisions involving the
            Interface, you should seek independent professional advice from an individual
            who is licensed and qualified in the area for which such advice would be
            appropriate.
          </p>
          <p class="my-1 text-center text-white">
            The Terms are not intended to, and do not, create or impose any fiduciary
            duties on us. To the fullest extent permitted by law, you acknowledge and
            agree that we owe no fiduciary duties or liabilities to you or any other
            party, and that to the extent any such duties or liabilities may exist at law
            or in equity, those duties and liabilities are hereby irrevocably disclaimed,
            waived, and eliminated. You further agree that the only duties and obligations
            that we owe you are those set forth expressly in the Terms.
          </p>
          <h4 class="text-center">NO WARRANTIES</h4>
          <p class="my-1 text-center text-white">
            The Interface is provided on an “AS IS” and “AS AVAILABLE” basis. To the
            fullest extent permitted by law, we disclaim any representations and
            warranties of any kind, whether express, implied, or statutory, including, but
            not limited to, the warranties of merchantability and fitness for a particular
            purpose. You acknowledge and agree that your access and use of the Interface
            is at your own risk. We do not represent or warrant that access to the
            Interface will be continuous, uninterrupted, timely, or secure; that the
            information contained in the Interface will be accurate, reliable, complete,
            or current; or that the Interface will be free from errors, defects, viruses,
            or other harmful elements. No advice, information, or statement that we make
            should be treated as creating any warranty concerning the Interface. We do not
            endorse, guarantee, or assume responsibility for any advertisements, offers,
            or statements made by third parties concerning the Interface.
          </p>
          <h4 class="text-center">TRADING AND INVESTMENT RISKS</h4>
          <p class="my-1 text-center text-white">
            There is considerable exposure to risk in any crypto-currency exchange
            transaction. Any transaction involving currencies involves risks including,
            but not limited to, the potential for changing economic conditions that may
            substantially affect the price or liquidity of a currency. Investments in
            crypto-currency exchange speculation may also be susceptible to sharp rises
            and falls as the relevant market values fluctuate. It is for this reason that
            when speculating in such markets it is advisable to use only risk capital.
          </p>
          <h4 class="text-center">ELECTRONIC TRADING RISKS</h4>
          <p class="my-1 text-center text-white">
            Before you engage in transactions using an electronic system, you should
            carefully review the rules and regulations of the exchanges offering the
            system and/or listing the instruments you intend to trade. Online trading has
            inherent risk due to system response and access times that may vary due to
            market conditions, system performance, and other factors. You should
            understand these and additional risks before trading.
          </p>
          <h4 class="text-center">COMPLIANCE OBLIGATIONS</h4>
          <p class="my-1 text-center text-white">
            The Interface may not be available or appropriate for use in all
            jurisdictions. By accessing or using the Interface, you agree that you are
            solely and entirely responsible for compliance with all laws and regulations
            that may apply to you. You further agree that we have no obligation to inform
            you of any potential liabilities or violations of law or regulation that may
            arise in connection with your access and use of the Interface and that we are
            not liable in any respect for any failure by you to comply with any applicable
            laws or regulations.
          </p>
          <h4 class="text-center">PERSONAL INFORMATION</h4>
          <p class="my-1 text-center text-white">
            Your submission of personal information through the store is regulated by our
            Privacy Policy. You are to view our Privacy Policy.
          </p>
          <h4 class="text-center">IFRAMES</h4>
          <p class="my-1 text-center text-white">
            Without prior approval and written permission, you may not create frames
            around our Webpages that alter in any way the visual presentation or
            appearance of our Website.
          </p>
          <h4 class="text-center">INDEMNIFICATION</h4>
          <p class="my-1 text-center text-white">
            You agree to indemnify, protect and hold harmless to Weedborn County,
            subsidiaries, affiliates, partners, officers, directors, agents, contractors,
            license, service providers, subcontractors, suppliers, interns and employees,
            harmless from any claim or demand, including reasonable attorney’s fees, made
            by any third party due to or arising out of your breach of these Terms and
            conditions or the documents they incorporate by reference or your infringement
            of any law or the rights of a third-party.
          </p>
          <h4 class="text-center">SEVERABILITY</h4>
          <p class="my-1 text-center text-white">
            In the event that any provision of these Terms and conditions is discovered to
            be unlawful, null or unenforceable, such provision shall notwithstanding be
            enforceable to the fullest extent permitted by applicable law, and the
            unenforceable portion shall be viewed to be cut off from these Terms of Use,
            such determination shall not affect the credibility
          </p>
          <h4 class="text-center">LIMITATION OF LIABILITY</h4>
          <p class="my-1 text-center text-white">
            Under no circumstances shall we or any of our officers, directors, employees,
            contractors, agents, affiliates, or subsidiaries be liable to you for any
            indirect, punitive, incidental, special, consequential, or exemplary damages,
            including (but not limited to) damages for loss of profits, goodwill, use,
            data, or other intangible property, arising out of or relating to any access
            to or use of the Interface, nor will we be responsible for any damage, loss,
            or injury resulting from hacking, tampering, or other unauthorized access to
            or use of the Interface, or from any access to or use of any information
            obtained by any unauthorized access to or use of the Interface. We assume no
            liability or responsibility for any: (a) errors, mistakes, or inaccuracies of
            content; (b) personal injury or property damage, of any nature whatsoever,
            resulting from any access to or use of the Interface; (c) unauthorized access
            to or use of any secure server or database in our control, or the use of any
            information or data stored therein; (d) interruption or cessation of function
            related to the Interface; (e) bugs, viruses, trojan horses, or the like that
            may be transmitted to or through the Interface; (f) errors or omissions in, or
            loss or damage incurred as a result of, the use of any content made available
            through the Interface; and (g) the defamatory, offensive, or illegal conduct
            of any third party. Under no circumstances shall we or any of our officers,
            directors, employees, contractors, agents, affiliates, or subsidiaries be
            liable to you for any claims, proceedings, liabilities, obligations, damages,
            losses, or costs in an amount exceeding the greater of (i) the amount you paid
            to us in exchange for access to and use of the Interface, or (ii) $100.00.
            This limitation of liability applies regardless of whether the alleged
            liability is based on contract, tort, negligence, strict liability, or any
            other basis, and even if we have been advised of the possibility of such
            liability. Some jurisdictions do not allow the exclusion of certain warranties
            or the limitation or exclusion of certain liabilities and damages.
            Accordingly, some of the disclaimers and limitations set forth in the Terms
            may not apply to you. This limitation of liability shall apply to the fullest
            extent permitted by law.and enforceability of any other remaining provisions.
          </p>
          <h4 class="text-center">CHANGES TO TERMS</h4>
          <p class="my-1 text-center text-white">
            We are committed to ensuring that our website is as useful and efficient as
            possible. For that reason, we reserve the right to make changes to the
            services, at any time. We will never charge you for any service without making
            it very clear to you precisely what you're paying for.
          </p>
          <p class="my-1 text-center text-white">
            Any new features which are added to the current site shall also be subject to
            this Terms and condition. You can always review the most current version of
            the Terms and conditions at any time on this page. We reserve the right to
            update, change or retrieve any part of these Terms and conditions by posting
            updates and/or changes to our website. It is your responsibility to check this
            page periodically for changes. Your continued use of or access to the website
            following the posting of any changes constitutes acceptance of those changes.
          </p>
          <h4 class="text-center">ENTIRE AGREEMENT</h4>
          <p class="my-1 text-center text-white">
            Our inability to exercise or enforce any right or provision of these Terms of
            Service shall not constitute a discharge of such right or provision.
          </p>
          <p class="my-1 text-center text-white">
            These Terms of Use and any policies or operating rules posted by us on this
            website or in respect to the Service constitutes the entire agreement and
            understanding between you and us and govern your use of the Service, pre-empt
            any prior or synchronous agreements, communications and proposals, whether
            oral or written, between you and us.
          </p>
          <p class="my-1 text-center text-white">
            Any ambiguities in the interpretation of these Terms of Use shall not be
            construed against the drafting party.
          </p>
          <h4 class="text-center">CONTACT INFORMATION</h4>
          <p class="my-1 text-center text-white">
            If you would like to: access, correct, register a complaint, or simply want
            more information please contact us:
          </p>
          <p class="my-1 text-center text-white">
            Email: info@bsitaly.info or Info@weedborncounty.io
          </p>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
export default {
  name: "Terms",
  inheritAttrs: false,
  components: {
    Footer,
  },
  mounted() {
    localStorage.clear();
  },
};
</script>

<style>
.my-box {
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
  background: #00000073;
  margin-top: 40px;
  border-radius: 6%;
  border: 4px solid #d7cd35;
}
.weedpaper {
  margin: 20px;
}
</style>
<style scoped>
/* .bg-img-1 {
  height: 100%;
} */
.social-icon {
  margin-top: 15px;
}
.social-icon i {
  font-size: 20px;
}
.social-icon a {
  background: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-icon i:hover {
  color: #6fa4f3 !important;
  transition: color 0.4s;
}
.login-content {
  width: 50%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
}
.login-btn .btn {
  padding: 15px 60px;
  margin-top: 2rem;
  font-size: 20px !important;
}
</style>
