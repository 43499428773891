<template>
  <div class="task_cart text-center mt-3" :class="expired ? 'expired' : ''">
    <h5 class="text-uppercase" :class="expired ? '' : 'text-white'">
      {{ apiRes.title }}
    </h5>
    <p class="text-uppercase text-success">{{ apiRes.reward }}</p>
    <a href="#" class="position-relative btn_hover slot_left">
      <img src="/images/buttons.png" alt="" class="btn_img" />
      <h3 class="btn_title" v-if="apiRes.points > 1000">
        {{ apiRes.my_points }}/{{ apiRes.points }}
      </h3>
      <h3 class="btn_title" v-else>
        {{ apiRes.my_points ? apiRes.my_points : 0 }}/{{ apiRes.points }}
      </h3>
    </a>
    <p class="mt-2 text-white" v-if="!expired">
      <Countdown
        :deadline="timeLeft"
        countdownSize="1.6rem"
        labelSize="0.6rem"
        :showDays="true"
        :showLabels="true"
        :mainColor="`#fff`"
        :labelColor="`#fff`"
        :flipAnimation="true"
      />
    </p>
    <h4 class="btn_title" v-if="expired">Expired</h4>
    <a
      href="#"
      class="position-relative btn_hover slot_left"
      v-if="apiRes.my_points >= apiRes.points"
      @click="claim(apiRes.type)"
    >
      <img src="/images/buttons.png" alt="" class="btn_img" />
      <h3 class="btn_title">Claim</h3>
    </a>
    <a
      href="#"
      class="position-relative btn_hover slot_left"
      @click="join(apiRes.type)"
      v-if="apiRes.my_points == null && !expired"
    >
      <img src="/images/buttons.png" alt="" class="btn_img" />
      <h3 class="btn_title">Join Now</h3>
    </a>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import HelperService from "../services/index";
import moment from "moment";
import { Countdown } from "vue3-flip-countdown";
export default {
  name: "TaskCard",
  props: ["row", "count", "openRoomsCount", "userTasks"],
  components: { Countdown },
  data() {
    return {
      isOpen: false,
      loading: true,
      timeLeft: null,
      apiRes: null,
    };
  },
  created() {
    this.apiRes = this.row;
    this.getTimeLeft(this.row);
  },
  async mounted() {
    var userData = this.userTasks.filter(
      (i) => i.key == localStorage.getItem("wax_user")
    );
    console.log(userData);
    if (userData.length > 0) {
      this.apiRes.my_points = userData[0].value.points;
    } else {
      this.apiRes.my_points = null;
    }
  },
  methods: {
    showAlert() {
      Swal.fire("Good job!", "You clicked the button!", "success");
    },
    getTimeLeft(stake) {
      let cUTC = moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss");
      let endDate = moment(stake.deadline).format("YYYY-MM-DD HH:mm:ss");
      this.expired = moment(cUTC).isAfter(moment(endDate));
      return (this.timeLeft = moment.utc(endDate).local().format("YYYY-MM-DD HH:mm:ss"));
    },
    selectOption() {
      this.isOpen = !this.isOpen;
    },
    claim(type) {
      const data = {
        contract: process.env.VUE_APP_CONTRACT,
        action: "task.claim",
        data: {
          account: localStorage.getItem("wax_user"),
          type: type,
        },
      };
      HelperService.signTrans(data, (res) => {
        if (res.processed) {
          HelperService.successMsg("Transaction has been made,refreshing");
          setTimeout(() => {
            this.$emit("transaction-done", res.processed);
          }, 2000);
        }
      });
    },
    join(type) {
      const data = {
        contract: process.env.VUE_APP_CONTRACT,
        action: "task.init",
        data: {
          account: localStorage.getItem("wax_user"),
          type: type,
        },
      };
      HelperService.signTrans(data, (res) => {
        if (res.processed) {
          HelperService.successMsg("Transaction has been made,refreshing");
          setTimeout(() => {
            this.$emit("transaction-done", res.processed);
          }, 2000);
        }
      });
    },
  },
};
</script>

<style scoped>
.slot_leftMain {
  position: absolute;
  top: 40%;
  left: 4%;
  font-size: 1.2rem;
}
.task_cart {
  border: 3px solid #fff;
  border-radius: 15px;
  padding: 10px;
  height: 370px;
}
.slot_left .btn_img {
  width: 120px;
  height: 45px;
}
.slot_left h3 {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: -4px;
  font-size: 18px;
}
.miner-inner-content {
  width: 65%;
  margin: auto;
}
.miner-wrapper {
  margin-top: 10%;
}
.right_slot {
  left: inherit;
  right: 4%;
}
.expired {
  border: 3px solid #6b6767;
  border-radius: 15px;
  padding: 10px;
}
.expired .btn_title {
  color: #6b6767;
}
.expired .text-uppercase {
  color: #6b6767;
}
</style>
