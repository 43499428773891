<template>
  <div class="large-card">
    <div class="card-img">
      <img src="/images/cp.png" class="img-fluid h-100" alt="" />
      <span v-if="!this.expired">
        <Countdown
          :deadline="timeLeft"
          countdownSize="0.8rem"
          labelSize="0.6rem"
          :showDays="false"
          :showLabels="false"
          :mainColor="`#fff`"
          :flipAnimation="true"
        />
      </span>
    </div>
    <div class="large-card-body text-center">
      <div class="button-wrapper slot_left">
        <a class="position-relative btn_hover" @click="transaction('claim.cp', stake.asset_id)" v-if="this.expired">
          <img src="/images/buttons.png" class="btn_img">
          <h3 class="btn_title">Claim</h3>
        </a>
        <a @click="transaction('unstake.cp',stake.asset_id)" v-if="!this.expired" class="position-relative btn_hover">
          <img src="/images/buttons.png" class="btn_img">
          <h3 class="btn_title">Unstake</h3>
        </a>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import HelperService from "../services/index";
  import moment from "moment";
  import { Countdown } from "vue3-flip-countdown";
  export default {
    name: "CardItem",
    props: ["stake"],
    components: { Countdown },
    data() {
      return {
        isOpen: false,
        loading: true,
        timeLeft: null,
      };
    },
    created() {
      this.getTimeLeft(this.stake);
    },
    methods: {
      getTimeLeft(stake) {
        let cUTC = moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss");
        let endDate = moment(stake.last_claimed)
          .add(4, "hours")
          .format("YYYY-MM-DD HH:mm:ss");
        this.expired = moment(cUTC).isAfter(moment(endDate));
        return (this.timeLeft = moment
          .utc(endDate)
          .local()
          .format("YYYY-MM-DD HH:mm:ss"));
      },
      claimable(item) {
        let cUTC = moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss");
        let endDate = moment(item.last_claimed).format("YYYY-MM-DD HH:mm:ss");
        const claimable = moment(cUTC).isAfter(moment(endDate));
        return claimable;
      },
      selectOption() {
        this.isOpen = !this.isOpen;
      },
      getImage(hash) {
        return HelperService.getImgUrl(hash);
      },
      transaction(action, id) {
        const data = {
          contract: process.env.VUE_APP_CONTRACT,
          action: action,
          data: {
            owner: localStorage.getItem("wax_user"),
            asset_id: id,
          },
        };
        HelperService.signTrans(data, (res) => {
          if (res.processed) {
            HelperService.successMsg("Transaction has been made,refreshing");
            setTimeout(() => {
              this.$emit("transaction-done", res.processed);
            }, 1000);
          }
        });
      }
    },
  };
  </script>

<style scoped>
.large-card {
  padding: 26px 10px;
  height: 280px;
  width: 200px;
  border: 3px solid #fff;
  border-radius: 15px;
  margin: auto;
}
.large-card .card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
  color: #fff;
  cursor: pointer;
  padding: 10px;
}

.button-wrapper a {
  color: brown !important;
}

.button-wrapper {
  width: 80%;
  margin: auto;
}

.button-wrapper .btn {
  width: 100%;
}

.slot_leftMain {
  position: absolute;
  top: 40%;
  left: 4%;
  font-size: 1.2rem;
}
.slot_left .btn_img {
  width: 80px;
}
.slot_left h3 {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 4px;
  font-size: 12px;
}

.btn {
  border: 0;
  border-radius: 7px;
  /* width: 100%; */
  text-align: center;
  padding: 3px 25px;
  margin-top: 5px;
  font-weight: bold;
  background: aqua;
  transition: all 0.4s;
}

.btn:hover {
  background: rgb(2, 135, 135);
}
</style>
  
  
  