<template>
  <div class="club-bg">
    <div class="container">
      <div class="club-content">
        <h1>The Club</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheClub",
};
</script>

<style scoped>
.club-content {
  margin-top: 12%;
}
</style>