<template>
  <div>
    <div class="shadow-layer grow-room rooms_main">
      <div class="container position-relative">
        <div class="miner-wrapper">
          <div class="back-btn">
            <router-link to="/floor">
              <img src="/images/back.png" class="img-fluid" alt="" />
            </router-link>
          </div>
          <section
            class="miner-slot bg-blackish position-relative nft_section_main"
            v-if="!loading && seedsChart.length"
          >
            <h4 class="pt-3 pl-5">Available Seed for harvest.</h4>
            <ul class="topImage_section d-flex">
              <li v-for="(item, i) in seedsChart" :key="i">
                <img :src="`/images/nfts/${item.asset_t}.png`" alt="Nfx Img" />
                <p class="text-center">{{ item.reward }}</p>
              </li>
            </ul>
          </section>
          <section
            class="miner-slot bg-blackish position-relative nft_section_main"
            v-else
          >
            <h6>No Seed found to harvest, floor 2 is unactive for now.</h6>
          </section>
          <template v-if="!loading && stakes">
            <FloorHouseCard
              v-for="(item, i) in stakes"
              :key="i"
              :mineRow="item"
              :index="i"
              :count="i"
              :openRoomsCount="openRoomsCount"
              v-on:transaction-done="init()"
            />
          </template>
          <section
            class="miner-slot bg-blackish position-relative nft_section_main"
            v-if="!loading && checkRooms(stakes)"
          >
            <div class="slot_leftMain">
              <a href="#" class="position-relative btn_hover slot_left">
                <img src="/images/buttons.png" alt="" class="btn_img" />
                <h3 class="btn_title">Room {{ stakes.length + 1 }}</h3>
              </a>
            </div>
            <div class="miner-inner-content choose_card_main py-3">
              <div class="row justify-content-center align-items-center">
                <div class="col-lg-3">
                  <div
                    class="large-card"
                    @click="selectOption('Pump')"
                    v-if="!selectedPump"
                  >
                    <a href="JavaScript:void(0)" class="card-img">
                      <i class="fa fa-plus fa-3x" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div class="mine-card text-center" v-else>
                    <div class="selected_image">
                      <img
                        class="img-fluid"
                        :src="`/images/nfts/${selectedPump.asset_t}.png`"
                        alt="card image"
                        @click="selectOption('Pump')"
                      />
                    </div>
                    <div class="mine-card__body pt-1">
                      <h5 class="card-title my-2">Pump</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div
                    class="large-card"
                    @click="selectOption('Lamp')"
                    v-if="!selectedLamp"
                  >
                    <a href="JavaScript:void(0)" class="card-img">
                      <i class="fa fa-plus fa-3x" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div class="mine-card text-center" v-else>
                    <div class="selected_image">
                      <img
                        class="img-fluid"
                        :src="`/images/nfts/${selectedLamp.asset_t}.png`"
                        alt="card image"
                        @click="selectOption('Lamp')"
                      />
                    </div>
                    <div class="mine-card__body pt-1">
                      <h5 class="card-title my-2">Lamp</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div
                    class="large-card"
                    @click="selectOption('Pot')"
                    v-if="!selectedPot"
                  >
                    <a href="JavaScript:void(0)" class="card-img">
                      <i class="fa fa-plus fa-3x" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div class="mine-card text-center" v-else>
                    <div class="selected_image">
                      <img
                        class="img-fluid"
                        :src="`/images/nfts/${selectedPot.asset_t}.png`"
                        alt="card image"
                        @click="selectOption('Pot')"
                      />
                    </div>
                    <div class="mine-card__body pt-1">
                      <h5 class="card-title my-2">Pot</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div
                    class="large-card"
                    @click="selectOption('Seed')"
                    v-if="!selectedSeed"
                  >
                    <a href="JavaScript:void(0)" class="card-img">
                      <i class="fa fa-plus fa-3x" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div class="mine-card text-center" v-else>
                    <div class="selected_image">
                      <img
                        class="img-fluid"
                        :src="`/images/nfts/${selectedSeed.asset_t}.png`"
                        alt="card image"
                        @click="selectOption('Seed')"
                      />
                    </div>
                    <div class="mine-card__body pt-1">
                      <h5 class="card-title my-2">Seed</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="mine-card text-center" v-if="!selectedHero">
                    <div class="selected_image" @click="selectOption('Hero')">
                      <i class="fa fa-plus fa-3x" aria-hidden="true"></i>
                    </div>
                    <div class="mine-card__body pt-1">
                      <h5 class="card-title my-2">Add Hero</h5>
                    </div>
                  </div>
                  <div class="mine-card text-center" v-else>
                    <div class="selected_image">
                      <img
                        class="img-fluid"
                        :src="`/images/nfts/${selectedHero.asset_t}.png`"
                        alt="card image"
                        @click="selectOption('Hero')"
                      />
                    </div>
                    <div class="mine-card__body pt-1">
                      <h5 class="card-title my-2">Hero</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="slot_leftMain choose_card_btn">
              <a href="#" class="position-relative btn_hover slot_left" @click="plant()">
                <img src="/images/buttons.png" alt="" class="btn_img" />
                <h3 class="btn_title">Stake</h3>
              </a>
            </div>
          </section>
          <section
            class="miner-slot bg-blackish position-relative nft_section_main"
            v-else
          >
            <div class="slot_leftMain">
              <a href="#" class="position-relative btn_hover slot_left">
                <img src="/images/buttons.png" alt="" class="btn_img" />
                <h3 class="btn_title">Room {{ stakes.length + 1 }}</h3>
              </a>
            </div>
            <div class="miner-inner-content choose_card_main py-3">
              <div class="row justify-content-center align-items-center">
                <div class="col-lg-3">
                  <div class="large-card">
                    <a href="JavaScript:void(0)" class="card-img">
                      <i class="fa fa-lock fa-3x" aria-hidden="true"></i>
                      Cost {{ (stakes.length + 1) * 5 }} WBC
                    </a>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="slot_leftMain choose_card_btn">
              <a
                href="#"
                class="position-relative btn_hover slot_left"
                @click="unlock(stakes.length + 1)"
              >
                <img src="/images/buttons.png" alt="" class="btn_img" />
                <h3 class="btn_title">Unlock</h3>
              </a>
            </div>
          </section>
        </div>
      </div>
    </div>
    <InventoryModal
      v-if="isOpen"
      :cards="info"
      :cardName="card"
      :floor="2"
      v-on:w-modal-hide-click="isOpen = !isOpen"
    />
  </div>
</template>

<script>
import FloorHouseCard from "../components/FloorHouseCard.vue";
import InventoryModal from "../components/InventoryModal.vue";
import { mapGetters } from "vuex";
import ApiService from "../services";
export default {
  components: {
    FloorHouseCard,
    InventoryModal,
  },
  computed: {
    ...mapGetters([
      "seeds",
      "selectedPump",
      "selectedSeed",
      "selectedPot",
      "selectedLamp",
      "selectedHero",
      "selectedExtractor",
      "selectedControlPanel",
      "selectedCharacter",
      "selectedTool",
    ]),
  },
  data() {
    return {
      showModal: false,
      activeItem: "mine",
      card: "",
      type: null,
      info: null,
      loading: true,
      isOpen: false,
      openRoomsCount: 1,
      stakes: [],
      seedsChart: [],
    };
  },
  created() {
    this.init();
    this.loadSeeds();
  },
  methods: {
    checkRooms(stakes) {
      if (stakes.length == 0) {
        return 1;
      } else {
        return this.openRoomsCount == stakes.length ? 0 : 1;
      }
    },
    getImgUrl(hash) {
      let url = "http://ipfs.io/ipfs/" + hash;
      return "//images.weserv.nl/?url=" + url + "&w=300";
    },
    openModal(type) {
      this.showModal = true;
      this.type = type;
    },
    selectOption(option) {
      this.isOpen = !this.isOpen;
      this.card = option;
    },
    closeModal() {
      this.selectOption();
      this.init();
    },
    loadSeeds() {
      ApiService.getTable(
        "seeds",
        null,
        (response) => {
          if (response) {
            this.seedsChart = response;
            this.$store.commit("setFloor2Seeds", response);
            this.loading = false;
          } else {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          ApiService.errorMsg(ApiService.errorFormat(error));
        }
      );
    },
    init() {
      ApiService.getTableScope(
        "floor2",
        null,
        null,
        (response) => {
          if (response) {
            console.log(response);
            this.openRoomsCount = response[0].allowed_room;
            this.stakes = response[0].details.length ? response[0].details[0].value : [];
            this.loading = false;
          } else {
            this.$swal("Floor is locked, go back and stake floor nft.");
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          ApiService.errorMsg(ApiService.errorFormat(error));
        }
      );
    },
    plant() {
      if (
        this.selectedPot &&
        this.selectedSeed &&
        this.selectedLamp.asset_id &&
        this.selectedPump.asset_id
      ) {
        var asset_ids = [
          this.selectedPot.asset_id,
          this.selectedLamp.asset_id,
          this.selectedPump.asset_id,
          this.selectedSeed.asset_id,
        ];
        if (this.selectedHero) {
          asset_ids.push(this.selectedHero.asset_id);
        }
        ApiService.plant(
          {
            from: localStorage.getItem("wax_user"),
            to: process.env.VUE_APP_CONTRACT,
            asset_ids: asset_ids,
            memo: "growhouse:seed:stake",
          },
          (res) => {
            if (res.processed) {
              ApiService.successMsg("Transaction completed successfully.");
              this.init();
            }
          },
          (error) => {
            console.log(ApiService.errorFormat(error));
            ApiService.errorMsg(ApiService.errorFormat(error));
          }
        );
      }
    },
    async unlock(room) {
      const data = {
        contract: "weedborncoin",
        action: "transfer",
        data: {
          from: localStorage.getItem("wax_user"),
          quantity: Number(room * 5).toFixed(4) + " WBC",
          to: process.env.VUE_APP_CONTRACT,
          memo: "unlocked:floor2:room",
        },
      };
      await ApiService.signTrans(
        data,
        (res) => {
          if (res.processed) {
            ApiService.successMsg("Transaction has been made.");
            this.init();
          }
        },
        (error) => {
          ApiService.errorMsg(ApiService.errorFormat(error));
        }
      );
    },
  },
};
</script>

<style scoped>
.selected_image img {
  max-height: 165px;
  width: 125px;
}
.mine-text {
  padding: 0 12px;
}
.slot_leftMain {
  position: absolute;
  top: 40%;
  left: 4%;
  font-size: 1.2rem;
}
.slot_left .btn_img {
  width: 80px;
}
.slot_left h3 {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 4px;
  font-size: 12px;
}
.mine-button {
  position: absolute;
  top: 43%;
  right: 4%;
}
.miner-inner-content {
  width: 65%;
  margin: auto;
}
.miner-wrapper {
  margin-top: 10%;
}
.choose_card_btn {
  left: inherit;
  right: 4%;
}
.topImage_section {
  padding: 4px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.topImage_section:hover {
  overflow-x: auto;
}
.topImage_section::-webkit-scrollbar {
  width: 3px;
  height: 13px;
}
.topImage_section li img {
  width: 130px;
}
/* Track */
.topImage_section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 1px;
}
/* Handle */
.topImage_section::-webkit-scrollbar-thumb {
  background: #e5924c;
  border-radius: 1px;
}

.large-card .card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 160px;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  width: 140px;
  border: 3px solid #fff;
  border-radius: 15px;
  margin: auto;
}
.miner-slot {
  background-color: rgb(0 0 0 / 80%);
}
.pl-5 {
  padding-left: 5px;
}
.rooms_main{
  overflow-y: hidden;
}
.rooms_main .miner-wrapper{
  overflow-y: auto;
  height: 550px;
}
</style>
