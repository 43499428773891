<template>
  <div class="container">
    <loading v-model:active="isLoading" />
    <transition name="modal fade">
      <div class="modal-mask modal-backdrop">
        <div class="w-100">
          <div class="modal-wrapper mx-3">
            <div class="modal-dialogs" role="document">
              <div class="modal-content">
                <h4 class="tm5 modal-title d-inline-block text-center">
                  Choose Your {{ cardName }}
                </h4>

                <a
                  href="JavaScript:void(0)"
                  class="btn-cross text-right close-modal text-white"
                  @click="hideModal($event)"
                  >&times;</a
                >
                <div class="modal-body mdScroll px-5 pb-5 text-center">
                  <p v-if="error">{{ error }}</p>

                  <div class="text-center mb-5 mb-lg-0 mb-md-0">
                    <div class="form-group w-50 mx-auto d-flex">
                      <select
                        class="form-control"
                        v-model="selectSchema"
                        placeholder="select schema"
                        @change="filter()"
                      >
                        <option value="" selected>Select rarity</option>
                        <option value="Common">Common</option>
                        <option value="Rare">Rare</option>
                        <option value="Epic">Epic</option>
                      </select>
                      <input
                        type="text"
                        class="form-control ms-2"
                        placeholder="search by name"
                        v-model="nameSearch"
                        @input="serach"
                      />
                    </div>
                    <div class="row no-gutters" v-if="!isLoading">
                      <div
                        class="col-md-4 mb-3"
                        v-for="(item, index) in info"
                        :key="index"
                      >
                        <div class="card" :class="item.selected ? 'selected' : ''">
                          <div
                            class="card-body p-0"
                            @click.prevent="
                              addCard(item);
                              isSelected = !isSelected;
                            "
                          >
                            <ImgBlock
                              :img="getImgUrl(item.template.template_id)"
                              alt=""
                              :local="true"
                              :defaultLink="getImgUrl(item.data.img)"
                            />
                            <p class="text-white fw-bold">
                              {{ item.asset_id }}
                            </p>
                            <p class="text-white fw-bold">
                              {{ item.data.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    class="btn btn-outline mb-4"
                    @click.prevent="init(cardName, page + 1)"
                    v-if="info.length > 0"
                  >
                    load more
                  </button>
                  <button
                    v-else
                    class="btn btn-outline mb-4"
                    @click.prevent="init(cardName, 1)"
                  >
                    Reset
                  </button>
                  <a
                    href="#"
                    class="position-relative btn_hover slot_left"
                    @click.prevent="hideModal()"
                  >
                    <img src="/images/buttons.png" alt="" class="btn_img" />
                    <h3 class="btn_title">Select</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ApiService from "../services";
import Loading from "vue-loading-overlay";
import ImgBlock from "./ImgBlock.vue";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Modal",
  components: {
    Loading,
    ImgBlock,
  },
  props: ["error", "showModel", "cards", "cardName", "floor"],
  showModal: true,
  data() {
    return {
      info: [],
      isLoading: false,
      isImageLoaded: false,
      apiRes: null,
      isSelected: false,
      selectSchema: "",
      nameSearch: "",
      page: 1,
    };
  },
  mounted() {
    this.init(this.cardName);
  },
  methods: {
    hideModal(e) {
      this.$emit("w-modal-hide-click", e);
    },
    filter() {
      this.info = this.apiRes.filter((row) => row.data.Type.includes(this.cardName));
      this.info = this.info.filter((a) => a.data.Rarity == this.selectSchema);
    },
    loaded() {
      this.isImageLoaded = true;
    },
    getImgUrl(id) {
      return `/images/nfts/${id}.png`;
    },
    addCard(item) {
      this.info = this.info.map((row, index) => {
        if (item.asset_id == row.asset_id) {
          this.info[index].selected = true;
        } else {
          this.info[index].selected = false;
        }
        return row;
      });
      this.$store.commit("addCard", item);
      this.$emit("w-modal-hide-click", []);
    },
    serach() {
      this.info = this.apiRes.filter((a) => {
        let name = a.data.name.toLowerCase();
        return name.includes(this.nameSearch);
      });
    },
    init(type, page = 1) {
      this.page = page;
      let pageName = this.$route.name;
      let schema =
        this.cardName == "Seed" ? "seeds" : this.cardName == "Hero" ? "heroes" : "tools";

      this.isLoading = true;
      ApiService.callApi(
        `collection_name=weedborncoun&page=${this.page}&limit=1000&schema_name=${schema}`,
        async (response) => {
          let res = response["data"];
          if (res["success"]) {
            if (pageName == "floor1" && this.cardName == "Seed") {
              this.apiRes = res["data"].filter((a) =>
                ["NY Diesel", "Girl Scout Cookies", "Death Rockstar"].includes(
                  a.data.name
                )
              );
            } else if (pageName == "floor2" && this.cardName == "Seed") {
              this.apiRes = res["data"].filter(
                (a) =>
                  !["NY Diesel", "Girl Scout Cookies", "Death Rockstar"].includes(
                    a.data.name
                  )
              );
            } else if (pageName == "floor2" && this.cardName != "Seed") {
              this.apiRes = res["data"].filter((a) => a.data.Rarity != "Common");
            } else {
              this.apiRes = res["data"];
            }
            this.info = this.apiRes.filter((row) => row.data.Type.includes(type));
            if (this.cardName == "Seed" && this.floor == 2) {
              var seeds = this.$store.state.floor2Seeds.map((row) => row.asset_t);
              this.info = this.info.filter((item) => {
                return seeds.find((i) => i == item.template.template_id) !== undefined;
              });
            }
          } else {
            this.info = [];
          }
          this.isLoading = false;
        }
      );
    },
  },
};
</script>
<style scoped>
.btn {
  border: inset;
}
.btn-outline {
  color: #fff;
  border: 2px solid #964f1c !important;
  margin-top: 10px;
}
.btn-outline:hover {
  background: #964f1c;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-y: hidden; */
}
.modal-backdrop .modal-wrapper .modal-content .card {
  height: 290px !important;
}
.modal-wrapper .modal-content {
  background: var(--primaryColor) !important;
  width: 760px !important;
  opacity: 0.9;
  border-radius: 46px !important;
  border: none !important;
  margin: auto;
  padding: 30px 0;
}

.mdScroll {
  overflow-y: scroll;
  height: 413px;
}
.mdScroll::-webkit-scrollbar {
  width: 4px;
}

.mdScroll::-webkit-scrollbar-thumb {
  background: transparent;
  height: 2px !important;
  border-radius: 20px;
}

.mdScroll:hover::-webkit-scrollbar-thumb {
  background: #766640;
}

.mdScroll::-webkit-scrollbar-thumb:hover {
  background: #7b61ff;
}

.mdScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-right: none;
  border-left: none;
}

.mdScroll::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 22px;
}

.mdScroll::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 28px;
}

.close-modal {
  position: absolute;
  top: 30px;
  right: 20px;
  font-size: 60px;
  line-height: 30px;
}
.modal-wrapper .modal-content .modal-body .card {
  width: 100% !important;
  border: none !important;
  margin-bottom: 20px;
  transition: all 0.2s;
  padding: 10px;
}
/* .modal-wrapper .modal-content .modal-body .btn {
  background: #fff;
  color: black;
  border-radius: 27px;
  font-weight: bold;
  font-size: 2rem;
  width: 150px;
  margin: 10px auto;
} */
.modal-wrapper .modal-content .card:hover {
  /*box-shadow: 10px 15px 20px rgba(209, 203, 203, 0.6);*/
  transform: translateY(-8px);
  transition: all 0.3;
}
.modal-wrapper .modal-content .card.selected {
  border: 5px solid #f7a35a !important;
}
.slot_left {
  width: 115px;
  display: block;
  margin: 0 auto;
}
.slot_left .btn_img {
  max-width: 100%;
  height: auto;
  width: 115px;
}
.slot_left h3 {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 13px;
  font-size: 18px;
}
@media only screen and (max-width: 992px) {
  .modal-wrapper .modal-content {
    width: 100% !important;
  }
}
</style>
