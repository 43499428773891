<template>
  <div class="shadow-layer grow-room">
    <div class="container position-relative">
      <div class="miner-wrapper">
        <div class="back-btn">
          <router-link to="/floor">
            <img src="/images/back.png" class="img-fluid" alt="" />
          </router-link>
        </div>

        <div class="miner-slot my-5 py-3">
          <div class="row justify-content-center">
            <div class="col-md-1">
              <a href="#" class="top-card">
                <img class="img-fluid" src="/images/nfts/496489.png" alt="" />
              </a>
            </div>
            <div class="col-md-1">
              <a href="#" class="top-card">
                <img class="img-fluid" src="/images/nfts/496491.png" alt="" />
              </a>
            </div>
            <div class="col-md-1">
              <a href="#" class="top-card">
                <img class="img-fluid" src="/images/nfts/496492.png" alt="" />
              </a>
            </div>
            <div class="col-md-1">
              <a href="#" class="top-card">
                <img class="img-fluid" src="/images/nfts/496493.png" alt="" />
              </a>
            </div>
            <div class="col-md-1">
              <a href="#" class="top-card">
                <img class="img-fluid" src="/images/nfts/496494.png" alt="" />
              </a>
            </div>
            <div class="col-md-1">
              <a href="#" class="top-card">
                <img class="img-fluid" src="/images/nfts/496495.png" alt="" />
              </a>
            </div>
            <div class="col-md-1">
              <a href="#" class="top-card">
                <img class="img-fluid" src="/images/nfts/496496.png" alt="" />
              </a>
            </div>
            <div class="col-md-1">
              <a href="#" class="top-card">
                <img class="img-fluid" src="/images/nfts/496497.png" alt="" />
              </a>
            </div>
            <div class="col-md-1">
              <a href="#" class="top-card">
                <img class="img-fluid" src="/images/nfts/496498.png" alt="" />
              </a>
            </div>
          </div>
        </div>

        <template v-if="!loading && stakes">
          <GrowHouseCard
            v-for="(item, i) in stakes"
            :key="i"
            :mineRow="item"
            :index="i"
            :count="i"
            :openRoomsCount="openRoomsCount"
            v-on:transaction-done="init()"
          />
        </template>
        <section
          class="miner-slot bg-blackish position-relative nft_section_main"
          v-if="!loading && checkRooms(stakes)"
        >
          <div class="slot_leftMain">
            <a href="#" class="position-relative btn_hover slot_left">
              <img src="/images/buttons.png" alt="" class="btn_img" />
              <h3 class="btn_title">Room {{ stakes.length + 1 }}</h3>
            </a>
          </div>
          <div class="miner-inner-content choose_card_main py-3">
            <div class="row justify-content-center align-items-center">
              <div class="col-lg-3">
                <div
                  class="large-card"
                  @click="selectOption('Pump')"
                  v-if="!selectedPump"
                >
                  <a href="JavaScript:void(0)" class="card-img">
                    <i class="fa fa-plus fa-3x" aria-hidden="true"></i>
                  </a>
                </div>
                <div class="mine-card text-center" v-else>
                  <div class="selected_image">
                    <img
                      class="img-fluid"
                      :src="`/images/nfts/${selectedPump.asset_t}.png`"
                      alt="card image"
                      @click="selectOption('Pump')"
                    />
                  </div>
                  <div class="mine-card__body pt-1">
                    <h5 class="card-title my-2">Pump</h5>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div
                  class="large-card"
                  @click="selectOption('Lamp')"
                  v-if="!selectedLamp"
                >
                  <a href="JavaScript:void(0)" class="card-img">
                    <i class="fa fa-plus fa-3x" aria-hidden="true"></i>
                  </a>
                </div>
                <div class="mine-card text-center" v-else>
                  <div class="selected_image">
                    <img
                      class="img-fluid"
                      :src="`/images/nfts/${selectedLamp.asset_t}.png`"
                      alt="card image"
                      @click="selectOption('Lamp')"
                    />
                  </div>
                  <div class="mine-card__body pt-1">
                    <h5 class="card-title my-2">Lamp</h5>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="large-card" @click="selectOption('Pot')" v-if="!selectedPot">
                  <a href="JavaScript:void(0)" class="card-img">
                    <i class="fa fa-plus fa-3x" aria-hidden="true"></i>
                  </a>
                </div>
                <div class="mine-card text-center" v-else>
                  <div class="selected_image">
                    <img
                      class="img-fluid"
                      :src="`/images/nfts/${selectedPot.asset_t}.png`"
                      alt="card image"
                      @click="selectOption('Pot')"
                    />
                  </div>
                  <div class="mine-card__body pt-1">
                    <h5 class="card-title my-2">Pot</h5>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div
                  class="large-card"
                  @click="selectOption('Seed')"
                  v-if="!selectedSeed"
                >
                  <a href="JavaScript:void(0)" class="card-img">
                    <i class="fa fa-plus fa-3x" aria-hidden="true"></i>
                  </a>
                </div>
                <div class="mine-card text-center" v-else>
                  <div class="selected_image">
                    <img
                      class="img-fluid"
                      :src="`/images/nfts/${selectedSeed.asset_t}.png`"
                      alt="card image"
                      @click="selectOption('Seed')"
                    />
                  </div>
                  <div class="mine-card__body pt-1">
                    <h5 class="card-title my-2">Seed</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="slot_leftMain choose_card_btn">
            <a href="#" class="position-relative btn_hover slot_left" @click="plant()">
              <img src="/images/buttons.png" alt="" class="btn_img" />
              <h3 class="btn_title">Stake</h3>
            </a>
          </div>
        </section>
        <section class="miner-slot bg-blackish position-relative nft_section_main" v-else>
          <div class="slot_leftMain">
            <a href="#" class="position-relative btn_hover slot_left">
              <img src="/images/buttons.png" alt="" class="btn_img" />
              <h3 class="btn_title">Room {{ stakes.length + 1 }}</h3>
            </a>
          </div>
          <div class="miner-inner-content choose_card_main py-3">
            <div class="row justify-content-center align-items-center">
              <div class="col-lg-3">
                <div class="large-card">
                  <a href="JavaScript:void(0)" class="card-img">
                    <i class="fa fa-lock fa-3x" aria-hidden="true"></i>
                    Cost {{ (stakes.length + 1) * 5 }} WBC
                  </a>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div class="slot_leftMain choose_card_btn">
            <a
              href="#"
              class="position-relative btn_hover slot_left"
              @click="unlock(stakes.length + 1)"
            >
              <img src="/images/buttons.png" alt="" class="btn_img" />
              <h3 class="btn_title">Unlock</h3>
            </a>
          </div>
        </section>
      </div>
    </div>
  </div>
  <InventoryModal
    v-if="isOpen"
    :cards="info"
    :cardName="card"
    v-on:w-modal-hide-click="isOpen = !isOpen"
  />
</template>

<script>
import GrowHouseCard from "../components/GrowHouseCard.vue";
import InventoryModal from "../components/InventoryModal.vue";
import { mapGetters } from "vuex";
import ApiService from "../services";
export default {
  components: {
    GrowHouseCard,
    InventoryModal,
  },
  computed: {
    ...mapGetters([
      "seeds",
      "selectedPump",
      "selectedSeed",
      "selectedPot",
      "selectedLamp",
      "selectedExtractor",
      "selectedControlPanel",
      "selectedCharacter",
      "selectedTool",
    ]),
  },
  data() {
    return {
      showModal: false,
      activeItem: "mine",
      card: "",
      type: null,
      info: null,
      loading: true,
      isOpen: false,
      openRoomsCount: 1,
      stakes: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkRooms(stakes) {
      if (stakes.length == 0) {
        return 1;
      } else {
        return this.openRoomsCount == stakes.length ? 0 : 1;
      }
    },
    getImgUrl(hash) {
      let url = "http://ipfs.io/ipfs/" + hash;
      return "//images.weserv.nl/?url=" + url + "&w=300";
    },
    openModal(type) {
      this.showModal = true;
      this.type = type;
    },
    selectOption(option) {
      this.isOpen = !this.isOpen;
      this.card = option;
    },
    closeModal() {
      this.selectOption();
      this.init();
    },
    init() {
      ApiService.getTableScope(
        "grow.house",
        null,
        null,
        (response) => {
          if (response) {
            this.openRoomsCount = response[0].allowed_room;
            this.stakes = response[0].details[0].value;
            this.loading = false;
          } else {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          ApiService.errorMsg(ApiService.errorFormat(error));
        }
      );
    },
    plant() {
      if (
        this.selectedPot &&
        this.selectedSeed &&
        this.selectedLamp.asset_id &&
        this.selectedPump.asset_id
      ) {
        ApiService.plant(
          {
            from: localStorage.getItem("wax_user"),
            to: process.env.VUE_APP_CONTRACT,
            asset_ids: [
              this.selectedPot.asset_id,
              this.selectedLamp.asset_id,
              this.selectedPump.asset_id,
              this.selectedSeed.asset_id,
            ],
            memo: "stake:seed",
          },
          (res) => {
            if (res.processed) {
              ApiService.successMsg("Transaction completed successfully.");
              this.init();
            }
          },
          (error) => {
            ApiService.errorMsg(ApiService.errorFormat(error));
          }
        );
      }
    },
    async unlock(room) {
      const data = {
        contract: "weedborncoin",
        action: "transfer",
        data: {
          from: localStorage.getItem("wax_user"),
          quantity: Number(room * 5).toFixed(4) + " WBC",
          to: process.env.VUE_APP_CONTRACT,
          memo: "unlocked:room",
        },
      };
      await ApiService.signTrans(
        data,
        (res) => {
          if (res.processed) {
            ApiService.successMsg("Transaction has been made.");
            this.init();
          }
        },
        (error) => {
          ApiService.errorMsg(ApiService.errorFormat(error));
        }
      );
    },
    unstake(id) {
      ApiService.transaction(
        "unstake",
        { owner: localStorage.getItem("wax_user"), id: id },
        (res) => {
          if (res.processed) {
            ApiService.successMsg("Transaction has been made.");
            this.init();
          }
        },
        (error) => {
          ApiService.errorMsg(ApiService.errorFormat(error));
        }
      );
    },
  },
};
</script>

<style scoped>
.top-card {
  display: block;
  width: 125px;
  margin: auto;
}
.mine-text {
  padding: 0 12px;
}
.slot_leftMain {
  position: absolute;
  top: 40%;
  left: 4%;
  font-size: 1.2rem;
}
.slot_left .btn_img {
  width: 80px;
}
.slot_left h3 {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 4px;
  font-size: 12px;
}
.mine-button {
  position: absolute;
  top: 43%;
  right: 4%;
}
.miner-inner-content {
  width: 65%;
  margin: auto;
}
.miner-wrapper {
  margin-top: 10%;
}
.choose_card_btn {
  left: inherit;
  right: 4%;
}
/*.modal_card{
  width: 200px;
  height: 200px;
  background: rgba(0,0,0,0.6);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}*/
.large-card .card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 160px;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  width: 140px;
  border: 3px solid #fff;
  border-radius: 15px;
  margin: auto;
}
.miner-slot {
  background-color: rgb(0 0 0 / 80%);
}
</style>
