<template>
  <div class="uni-bg">
    <div class="container">
      <div class="uni-content mb-3 page_heading">
        <h1>University</h1>
      </div>
      <div class="row mobile_scroll">
        <div class="col-12 col-lg-4 col-md-6">
          <div class="card text-center university_card_main">
            <div class="card-body">
              <h5 class="card-title fw-bold">NY Diesel</h5>
              <h3 class="fw-bold" v-if="!loading && info">{{ info.ny_diesel }}</h3>
              <h3 class="fw-bold" v-else>0</h3>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-md-6">
          <div class="card text-center university_card_main">
            <div class="card-body">
              <h5 class="card-title fw-bold">Girl Scout Cookies</h5>
              <h3 class="fw-bold" v-if="!loading && info">
                {{ info.girl_scout_cookies }}
              </h3>
              <h3 class="fw-bold" v-else>0</h3>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-md-6">
          <div class="card text-center university_card_main">
            <div class="card-body">
              <h5 class="card-title fw-bold">Death Rockstar</h5>
              <h3 class="fw-bold" v-if="!loading && info">{{ info.death_rockstar }}</h3>
              <h3 class="fw-bold" v-else>0</h3>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 col-md-6" v-for="(item, i) in players" :key="i">
          <div class="card text-center university_card_main">
            <div class="card-body">
              <img
                class="img-fluid seed-img"
                :src="getImage(item.key)"
                alt="card image"
              />
              <h4 class="fw-bold">{{ getName(item.key) }}</h4>
              <h3 class="fw-bold">{{ item.value }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelperService from "../services/index";
export default {
  name: "StakePage",
  data() {
    return {
      isOpen: false,
      loading: true,
      info: null,
      players: null,
      seeds: null,
    };
  },
  created() {
    this.isLoading = true;
    HelperService.callApiBySchema(
      `collection_name=weedborncoun&schema_name=seeds`,
      async (response) => {
        let res = response["data"];
        if (res["success"]) {
          this.seeds = res["data"];
        }
        this.isLoading = false;
      }
    );
  },
  mounted() {
    HelperService.getTableScope("user.xp", null, null, (res) => {
      if (res) {
        this.info = res[0];
        // this.loading = false;
      }
    });
    HelperService.getTableScope("player.xp", null, null, (res) => {
      if (res) {
        this.players = res[0].xp;
      }
    });
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },
  methods: {
    getImage(id) {
      return `/images/nfts/${id}.png`;
    },
    getName(templId) {
      if (this.seeds) {
        const asset = this.seeds.filter((x) => x.template_id == templId);
        console.log(asset);
        if (asset) {
          return asset[0].immutable_data.name;
        }
      }
    },
  },
};
</script>
<style scoped>
.uni-content {
  margin-top: 12%;
}
.university_card_main {
  width: 80%;
  height: 390px;
  background: url(/images/Senza.png) no-repeat;
  background-size: 100% 100%;
  border: none !important;
}
.university_card_main .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  margin: 0 auto;
}
.university_card_main small {
  font-size: 10px;
  font-weight: 600;
}
.seed-img {
  width: 120px;
  margin: 0 auto;
}
</style>
