<template>
  <div class="bg-img-1">
    <div class="h-100">
      <section class="box-lg">
        <div class="box-content">
          <h3 class="text-center">Copyright Policy</h3>
          <p class="my-1 text-center text-white">Copyright © Weedborn County.</p>
          <p class="my-1 text-center text-white">
            All Rights Reserved. All files and information contained in this Website are
            copyright by BSItaly di Pizzo Matteo Mario (VAT NUMBER: 02679240065) and may
            not be duplicated, copied, modified or adapted, in any way without our written
            permission. Our Website may contain our service marks or trademarks as well as
            those of our affiliates or other companies, in the form of words, graphics,
            and logos.
          </p>
          <p class="my-1 text-center text-white">
            Your use of our Website, Blog or Services does not constitute any right or
            license for you to use our service marks or trademarks, without the prior
            written permission of BSItaly di Pizzo Matteo Mario (VAT NUMBER: 02679240065).
            Our Content, as found within our Website, Blog and Services, is protected
            under copyrights. The copying, redistribution, use or publication by you of
            any such Content, is strictly prohibited. Your use of our Website and Services
            does not grant you any ownership rights to our Content.
          </p>
          <h4 class="text-center">ACCEPTABLE USE</h4>
          <p class="my-1 text-center text-white">You must not:</p>
          <p class="my-1 text-center text-white">
            (a) use our website in any way or take any action that causes, or may cause,
            damage to the website or impairment of the performance, availability or
            accessibility of the website;
          </p>
          <p class="my-1 text-center text-white">
            (b) use our website in any way that is unlawful, illegal, fraudulent or
            harmful, or in connection with any unlawful, illegal, fraudulent or harmful
            purpose or activity;
          </p>
          <p class="my-1 text-center text-white">
            (c) use our website to copy, store, host, transmit, send, use, publish or
            distribute any material which consists of (or is linked to) any spyware,
            computer virus, Trojan horse, worm, keystroke logger, rootkit or other
            malicious computer software;
          </p>
          <p class="my-1 text-center text-white">
            or (d) conduct any systematic or automated data collection activities
            (including without limitation scraping, data mining, data extraction and data
            harvesting) on or in relation to our website without our express written
            consent.
          </p>
          <h4 class="text-center">REPORT ABUSE</h4>
          <p class="my-1 text-center text-white">
            If you found any unlawful material or activity on our website, or any material
            or activity that breaches this notice, please let us know. You can let us know
            about any such material or activity by email us at info@bsitaly.info or
            info@weedborncounty.io.
          </p>
          <h4 class="text-center">ENFORCEMENT OF COPYRIGHT</h4>
          <p class="my-1 text-center text-white">
            We take the protection of our copyright very seriously.
          </p>
          <p class="my-1 text-center text-white">
            If we discover that you have used our copyright materials in contravention of
            the license set out in this notice, we may bring legal proceedings against
            you, seeking monetary damages and/or an injunction to stop you using those
            materials. You could also be ordered to pay legal costs.
          </p>

          <h4 class="text-center">PERMISSIONS</h4>
          <p class="my-1 text-center text-white">
            You may request permission to use the copyright materials on our website by
            email us at info@bsitaly.info or info@weedborncounty.io.
          </p>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
export default {
  name: "CopyRights",
  inheritAttrs: false,
  components: {
    Footer,
  },
  mounted() {
    localStorage.clear();
  },
};
</script>

<style>
.box-lg {
  width: 70%;
  display: flex;
  height: 800px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
  background: #000000a6;
  margin-top: 40px;
  border-radius: 6%;
  border: 4px solid #d7cd35;
  padding: 10px;
}
.box-content {
  align-items: center;
  justify-content: center;
  height: 700px;
  overflow: auto;
}
.weedpaper {
  margin: 20px;
}
</style>
<style scoped>
/* .bg-img-1 {
  height: 100%;
} */
.social-icon {
  margin-top: 15px;
}
.social-icon i {
  font-size: 20px;
}
.social-icon a {
  background: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-icon i:hover {
  color: #6fa4f3 !important;
  transition: color 0.4s;
}
.login-content {
  width: 50%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
}
.login-btn .btn {
  padding: 15px 60px;
  margin-top: 2rem;
  font-size: 20px !important;
}
</style>
