<template>
  <div class="bg-img-1">
    <div class="h-100">
      <section class="my-box">
        <p class="my-1 text-center text-white">
          Blockchain Solution di Pizzo Matteo Mario
        </p>

        <p class="my-1 text-center text-white">
          Headquarter Address: Via Caniggia 6, 15121, Alessandria, Italy
        </p>

        <p class="my-1 text-center text-white">VAT Number Registration: 02679240065</p>

        <p class="my-1 text-center text-white">Contacts: weedborncounty@gmail.com</p>
      </section>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue";
export default {
  name: "Imprint",
  inheritAttrs: false,
  components: {
    Footer,
  },
  mounted() {
    localStorage.clear();
  },
};
</script>

<style>
.my-box {
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
  background: #00000073;
  margin-top: 40px;
  border-radius: 6%;
  border: 4px solid #d7cd35;
}
.weedpaper {
  margin: 20px;
}
</style>
<style scoped>
/* .bg-img-1 {
  height: 100%;
} */
.social-icon {
  margin-top: 15px;
}
.social-icon i {
  font-size: 20px;
}
.social-icon a {
  background: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-icon i:hover {
  color: #6fa4f3 !important;
  transition: color 0.4s;
}
.login-content {
  width: 50%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
}
.login-btn .btn {
  padding: 15px 60px;
  margin-top: 2rem;
  font-size: 20px !important;
}
</style>
