<template>
  <div class="grow-room">
    <div class="container text-center">
      <div class="row roomCards justify-content-center px-5" v-if="blocked">
        <div class="card unlock-card">
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center h-100">
              <a
                href="JavaScript:void(0)"
                class="text-center d-block"
                @click="unlockplot()"
              >
                <i class="fa fa-lock fa-2x" aria-hidden="true"></i>
              </a>
              <h4 class="mt-2">Unlock</h4>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row roomCards justify-content-center px-5"
        v-if="loading == false"
      >
        <div class="col-md-4">
          <div class="card custom-card">
            <div class="card-body" v-if="!selectedPot">
              <div class="d-flex flex-column justify-content-center h-100">
                <a
                  href="JavaScript:void(0)"
                  class="text-center d-block"
                  @click="showSelection('Pot')"
                >
                  <i class="fal fa-plus mt-3 fa-3x"></i>
                </a>
                <h4 class="mt-2">Pot</h4>
              </div>
            </div>
            <div class="card-body" v-else>
              <img
                class="card-img-top"
                :src="getImgUrl(selectedPot.img)"
                alt=""
                @click.prevent="showSelection('Pot')"
              />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card custom-card">
            <div class="card-body" v-if="!selectedPump">
              <div class="d-flex flex-column justify-content-center h-100">
                <a
                  href="JavaScript:void(0)"
                  class="text-center d-block"
                  @click="showSelection('Pump')"
                >
                  <i class="fal fa-plus mt-3 fa-3x"></i>
                </a>
                <h4 class="mt-2">Water Pump</h4>
              </div>
            </div>
            <div class="card-body" v-else>
              <img
                class="card-img-top"
                :src="getImgUrl(selectedPump.img)"
                alt=""
                @click.prevent="showSelection('Pump')"
              />
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card custom-card">
            <div class="card-body" v-if="!selectedLamp">
              <div class="d-flex flex-column justify-content-center h-100">
                <a
                  href="JavaScript:void(0)"
                  class="text-center d-block"
                  @click="showSelection('Lamp')"
                >
                  <i class="fal fa-plus mt-3 fa-3x"></i>
                </a>
                <h4 class="mt-2">Lamp</h4>
              </div>
            </div>
            <div class="card-body" v-else>
              <img
                class="card-img-top"
                :src="getImgUrl(selectedLamp.img)"
                alt=""
                @click.prevent="showSelection('Lamp')"
              />
            </div>
          </div>
        </div>
        <div class="col-md-4" v-if="plot == 0 && room == 1">
          <div class="card custom-card">
            <div
              class="card-body"
              v-if="toolstakes == null || toolstakes.extractor_id == 0"
            >
              <div class="d-flex flex-column justify-content-center h-100">
                <a
                  href="JavaScript:void(0)"
                  class="text-center d-block"
                  @click="upStakeMdl('Extractor')"
                >
                  <i class="fal fa-plus mt-3 fa-3x"></i>
                </a>
                <h4 class="mt-2">Extractor</h4>
              </div>
            </div>
            <div class="card-body" v-show="toolstakes.extractor_id !== 0">
              <img class="card-img-top" src="/images/x.png" alt="" />
              <a
                href="JavaScript:void(0)"
                class="btn btn-sm btn-danger"
                @click="unstaketool('unstakextor')"
              >
                <h5 class="mt-2">Unstake</h5>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-if="floor == 1 && room == 1 && plot == 0">
          <div class="card custom-card">
            <div
              class="card-body"
              v-if="toolstakes == null || toolstakes.control_panel_id == 0"
            >
              <div class="d-flex flex-column justify-content-center h-100">
                <a
                  href="JavaScript:void(0)"
                  class="text-center d-block"
                  @click="upStakeMdl('Control Panel')"
                >
                  <h5 class="mt-2">Set Control Panel</h5>
                </a>
              </div>
            </div>
            <div
              class="card-body"
              v-show="!toolstakes && toolstakes.control_panel_id !== 0"
            >
              <img class="card-img-top" src="/images/cp.png" alt="" />
              <a
                href="JavaScript:void(0)"
                class="btn btn-sm btn-danger"
                @click="unstaketool('unstakecp')"
              >
                <h5 class="mt-2">Unstake</h5>
              </a>
            </div>
          </div>
        </div>
        <div
          class="action-button mt-10"
          v-if="
            selectedPot &&
            selectedLamp &&
            selectedPump &&
            toolstakes &&
            !toolstakes.lamp_asset_id
          "
        >
          <a href="#" class="position-relative" @click.prevent="stake()">
            <img src="/images/buttons.png" alt="" class="action-img" />
            <h3 class="action-title">Stake</h3>
          </a>
        </div>
        <div class="action-button mt-10" v-if="canPlant()">
          <a
            href="#"
            v-if="selectedSeed"
            class="position-relative"
            @click.prevent="plant()"
          >
            <img src="/images/buttons.png" alt="" class="action-img" />
            <h3 class="action-title">Plant</h3>
          </a>
          <a
            href="#"
            v-if="!selectedSeed || harvested == null"
            class="position-relative"
            @click.prevent="showSelection('Seed')"
          >
            <img src="/images/buttons.png" alt="" class="action-img" />
            <h3 class="action-title">Select Seed</h3>
          </a>
          <a
            href="#"
            class="position-relative"
            @click.prevent="harvest()"
            v-if="selectedSeed"
          >
            <img src="/images/buttons.png" alt="" class="action-img" />
            <h3 class="action-title">Harvest</h3>
          </a>
        </div>
      </div>

      <InventoryModal
        :error="error"
        v-if="showModal"
        :cards="info"
        :cardName="card"
        v-on:w-modal-hide-click="showModal = false"
      />
      <MakeStake
        :error="error"
        v-if="showStakeMdl"
        :cards="info"
        :cardName="card"
        v-on:w-modal-hide-click="showStakeMdl = false"
      />
    </div>
  </div>
</template>

<script>
import InventoryModal from "../components/InventoryModal.vue";
import MakeStake from "../components/MakeStake";
import ApiService from "../services";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "GrowHouse",
  computed: {
    ...mapGetters([
      "seeds",
      "selectedPump",
      "selectedSeed",
      "selectedPot",
      "selectedLamp",
      "selectedExtractor",
      "selectedControlPanel",
    ]),
  },
  components: {
    InventoryModal,
    MakeStake,
  },
  data() {
    return {
      showModal: false,
      showStakeMdl: false,
      card: null,
      toolstakes: null,
      harvested: null,
      room: 0,
      plot: 0,
      floor: 0,
      loading: true,
      blocked: false,
      seedModal: false,
    };
  },
  mounted() {
    this.room = this.$route.params.room;
    this.floor = this.$route.params.id;
    this.plot = this.$route.params.plot;
    this.$store.dispatch("getSeeds");
    this.getStakeTools();
  },
  methods: {
    canPlant() {
      if (!this.toolstakes) {
        return false;
      } else {
        if (
          this.toolstakes.pump_asset_id &&
          this.toolstakes.pot_asset_id &&
          this.toolstakes.lamp_asset_id
        ) {
          return true;
        }
      }
      return false;
    },
    getImgUrl(hash) {
      if (hash == "pot") {
        return "/images/pot_common.png";
      } else if (hash == "pump") {
        return "/images/pump_common.png";
      } else if (hash == "lamp") {
        return "/images/lamp_common.png";
      } else {
        let url = "http://ipfs.io/ipfs/" + hash;
        return "//images.weserv.nl/?url=" + url + "&w=500";
      }
    },
    showSelection(option) {
      this.card = option;
      this.showModal = true;
    },
    upStakeMdl(option) {
      this.card = option;
      this.showStakeMdl = true;
    },
    getStakeTools() {
      ApiService.getTableScope("plantspaces", null, null, (res) => {
        if (res) {
          const space =
            this.plot + this.room * 3 + ((this.floor - 1) * 9 + 0 * 45);
          const toolstakes = res.filter(
            (item) => item.space == this.plot || item.space == space
          );
          if (toolstakes.length) {
            this.toolstakes = toolstakes[0];
            this.$store.commit("addCardDefault", this.toolstakes);
            this.loading = false;
            this.blocked = false;
          } else {
            this.blocked = true;
            this.loading = false;
          }
        } else {
          this.blocked = true;
        }
      });
      ApiService.getTableScope("harvested", null, null, (res) => {
        if (res) {
          this.harvested = res[0];
          if (this.harvested.length) {
            this.loading = false;
          }
        }
      });
    },
    async stake() {
      const data = {
        floor: this.floor - 1,
        room: this.room - 1,
        plot: this.plot,
        owner: localStorage.getItem("wax_user"),
        house: 0,
        lamp_asset_id: this.selectedLamp.asset_id,
        pump_asset_id: this.selectedPump.asset_id,
        pot_asset_id: this.selectedPot.asset_id,
      };
      await ApiService.staketool(data);
    },
    async unstaketool(action) {
      const data = {
        owner: localStorage.getItem("wax_user"),
        floor: this.floor - 1,
        room: this.room - 1,
        house: 0,
      };
      await ApiService.transact(
        action,
        data,
        (res) => {
          if (res.processed) {
            toast.success("Unstaked...");
            setTimeout(() => {
              this.$router.go();
            }, 2000);
          }
        },
        (error) => {
          this.error = ApiService.errorFormat(error);
          ApiService.errorMsg(this.error);
        }
      );
    },
    async harvest() {
      const data = {
        owner: localStorage.getItem("wax_user"),
        floor: this.floor - 1,
        room: this.room - 1,
        plot: this.plot,
        house: 0,
      };
      await ApiService.transact(
        "harvest",
        data,
        (res) => {
          if (res.processed) {
            toast.success("Harvested...");
            setTimeout(() => {
              this.$router.go();
            }, 2000);
          }
        },
        (error) => {
          this.error = ApiService.errorFormat(error);
          ApiService.errorMsg(this.error);
        }
      );
    },
    async plant() {
      if (
        this.toolstakes &&
        this.toolstakes.lamp_asset_id &&
        this.toolstakes.pump_asset_id &&
        this.toolstakes.pot_asset_id &&
        this.selectedSeed.asset_id
      ) {
        const data = {
          plant: {
            floor: this.floor - 1,
            room: this.room - 1,
            plot: this.plot,
            owner: localStorage.getItem("wax_user"),
            house: 0,
            seed_id: Number(this.selectedSeed.asset_id),
          },
          seed: {
            owner: localStorage.getItem("wax_user"),
            seed_id: Number(this.selectedSeed.asset_id),
          },
          trans: {
            from: localStorage.getItem("wax_user"),
            to: process.env.VUE_APP_CONTRACT,
            asset_ids: [Number(this.selectedSeed.asset_id)],
            memo: "",
          },
        };
        await ApiService.plant(data);
      } else {
        toast.error("Check invalid data provided");
      }
    },
    selectSeed() {
      this.seedModal = true;
    },
    async unlockplot() {
      const data = {
        owner: localStorage.getItem("wax_user"),
        floor: this.floor - 1,
        room: this.room - 1,
        plot: this.plot,
        house: 0,
      };
      await ApiService.transact(
        "unlockplot",
        data,
        (res) => {
          if (res.processed) {
            toast.success("Unlocked...");
            setTimeout(() => {
              this.$router.go();
            }, 2000);
          }
        },
        (error) => {
          this.error = ApiService.errorFormat(error);
          ApiService.errorMsg(this.error);
        }
      );
    },
  },
};
</script>
<style scoped>
.grow-room .container {
  margin-top: 12%;
}
.custom-card {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 233px;
  height: 300px;
  margin: 20px auto 20px auto;
}
.unlock-card {
  width: 200px;
  height: 200px;
  background: transparent;
  border: 1px solid #fff;
  margin-bottom: 20px;
}

.action-button .action-img {
  width: 150px;
}
.action-button .action-title {
  position: absolute;
  top: -7px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
}
</style>
