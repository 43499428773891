<template>
  <div class="grow-room">
    <div class="container">
      <nav class="navbar navbar-expand-sm w-50 mx-auto">
        <button
          class="navbar-toggler d-lg-none"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavId"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="collapsibleNavId">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="#">
                <div class="d-flex align-items-center">
                  <div class="nav-arrow-img" v-if="counter == 5">
                    <img
                      src="/images/Senza_titolo-16.png"
                      alt=""
                      class="img-fluid"
                      @click="decrement"
                    />
                  </div>
                  <div class="nav-arrow-img" v-else>
                    <img
                      src="/images/Senza_titolo-17.png"
                      alt=""
                      class="img-fluid"
                      @click="increment"
                    />
                  </div>
                  <div class="nav-text">
                    <h5>floor</h5>
                    <span>{{ counter }} 0f 5</span>
                  </div>
                </div>
              </a>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/">
                <div class="d-flex align-items-center">
                  <div class="nav-text">
                    <h5>room</h5>
                    <span>1 0f 5</span>
                  </div>
                  <div class="nav-arrow-img">
                    <img
                      src="/images/Senza titolo-5.png"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </nav>

      <section class="content mt-5">
        <div class="row">
          <div class="col-md-3">
            <div class="large-card text-center">
              <div class="large-card__img">
                <img src="/images/cp.png" class="img-fluid" alt="" />
              </div>
              <div class="large-card__img mt-5">
                <h4>Control Panel</h4>
                <div class="cp-img">
                  <img
                    src="/images/cp.png"
                    class="img-fluid"
                    alt="control-panel"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="large-card text-center lamp-card">
              <div class="large-card__img lamp-img">
                <img src="/images/lamp_common.png" class="img-fluid" alt="" />
              </div>
              <div class="img-box position-relative">
                <div class="tree-img">
                  <img src="/images/step_3.png" class="img-fluid" alt="" />
                </div>
                <div class="pot-img d-flex">
                  <img src="/images/pot_common.png" class="img-fluid" alt="" />
                  <img src="/images/pump_common.png" class="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "InGame",
  data() {
    return {
      counter: 1,
    };
  },
  methods: {
    increment() {
      if (this.counter < 5) {
        this.counter++;
      }
    },
    decrement() {
      if (this.counter >= 5) {
        this.counter--;
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  margin: 0;
  padding: 0;
}

.navbar-nav {
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 18px;
}

.navbar-nav .nav-item {
  margin-left: 40px;
}
.nav-img {
  width: 85px;
}
.nav-arrow-img {
  width: 62px;
}
.nav-text {
  color: #f8a45b;
  margin-left: 20px;
  text-align: center;
}
.nav-text h5 {
  margin-bottom: 0;
  color: #f8a45b;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 0.4;
}
.nav-text span {
  font-size: 14px;
}

.lamp-card {
  display: flex;
  flex-direction: column;
  height: 631px;
}
.large-card .large-card__img {
  width: 180px;
  position: relative;
  margin: auto;
}

.lamp-img {
  width: 115px !important;
}

.img-box {
  margin-top: 100px;
}

.img-box .tree-img {
  width: 120px;
  margin: auto;
  position: relative;
  right: 26px;
  top: -6px;
}
.img-box .pot-img {
  width: 130px;
  margin: auto;
  position: relative;
  top: -29px;
}
.img-box .pot-img img:first-child {
  width: 70px;
}
.img-box .pot-img img:last-child {
  position: absolute;
  left: 50px;
  bottom: 0;
  width: 100%;
  height: 130px;
}
.img-box .pump-img {
  position: absolute;
  top: 40px;
  right: 10px;
  width: 125px;
}
.large-card .large-card__img h4 {
  margin: 0;
  font-size: 15px;
  text-align: center;
}
.large-card .cp-img {
  width: 150px;
  margin: auto;
}
</style>
