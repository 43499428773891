<template>
  <div class="container">
    <loading v-model:active="isLoading" />
    <transition name="modal fade">
      <div class="modal-mask modal-backdrop">
        <div class="">
          <div class="modal-wrapper">
            <div class="modal-dialogs" role="document">
              <div class="modal-content">
                <h1 class="tm5 modal-title d-inline-block">Choose Your {{ cardName }}</h1>
                <a
                  href="JavaScript:void(0)"
                  class="btn-cross text-right close-modal"
                  @click="hideModal($event)"
                  >&times;</a
                >
                <div class="modal-body mdScroll px-5 pb-5">
                  <p v-if="error">{{ error }}</p>
                  <div class="text-center">
                    <div class="row no-gutters" v-if="!isLoading">
                      <div
                        class="col-md-4 mb-3"
                        v-for="(item, index) in info"
                        :key="index"
                      >
                        <div class="card" :class="item.selected ? 'selected' : ''">
                          <div class="card-body p-0">
                            <img
                              class="card-img-top"
                              v-show="isImageLoaded"
                              :src="getImgUrl(item.data.img)"
                              alt=""
                              @click.prevent="addCard(item)"
                              @click="isSelected = !isSelected"
                              @load="loaded"
                            />
                            <img
                              v-if="!isImageLoaded"
                              class="card-img-top"
                              :src="'/images/loader.gif'"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ApiService from "../services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "MakeStake",
  components: {
    Loading,
  },
  props: ["error", "showModel", "cards", "cardName", "foods", "supplies"],
  showModal: true,
  data() {
    return {
      info: null,
      isLoading: false,
      isImageLoaded: false,
      apiRes: null,
      floor: null,
      isSelected: false,
    };
  },
  mounted() {
    this.init(this.cardName);
    this.floor = this.$route.params.id;
  },
  methods: {
    hideModal(e) {
      this.$emit("w-modal-hide-click", e);
    },
    loaded() {
      this.isImageLoaded = true;
    },
    getImgUrl(hash) {
      let url = "http://ipfs.io/ipfs/" + hash;
      return "//images.weserv.nl/?url=" + url + "&w=400";
    },
    addCard(item) {
      const data = {
        floor: this.floor - 1,
        owner: localStorage.getItem("wax_user"),
        house: 0,
      };
      if (item.data.name == "Control Panel") {
        data.control_panel_id = item.asset_id;
      } else {
        data.extractor_id = item.asset_id;
      }
      console.log(data);
      ApiService.transact(
        item.data.name == "Control Panel" ? "stakecp" : "stakextor",
        data,
        (res) => {
          if (res.processed) {
            toast.success("Staked ! transaction successfully completed");
            setTimeout(() => {
              this.$router.go();
            }, 2000);
          }
        },
        (error) => {
          toast.error(ApiService.errorFormat(error));
        }
      );
    },
    init(type) {
      if (this.apiRes) {
        this.info = this.apiRes.filter((row) => row.data.Type == type);
      } else {
        this.isLoading = true;
        ApiService.callApi(
          `collection_name=weedborncoun&page=1&limit=1000&schema_name=test`,
          async (response) => {
            let res = response["data"];
            if (res["success"]) {
              this.apiRes = res["data"];
              this.info = res["data"];
              this.info = this.apiRes.filter((row) => row.data.Type == type);
            } else {
              this.info = [];
            }
            this.isLoading = false;
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow-y: hidden; */
}
.modal-backdrop .modal-wrapper .modal-content .card {
  height: 290px !important;
}
.modal-wrapper .modal-content {
  background: var(--primaryColor) !important;
  width: 760px !important;
  opacity: 0.9;
  border-radius: 46px !important;
  border: none !important;
  margin: auto;
}

.mdScroll {
  overflow-y: scroll;
  height: 413px;
}
.mdScroll::-webkit-scrollbar {
  width: 4px;
}

.mdScroll::-webkit-scrollbar-thumb {
  background: transparent;
  height: 2px !important;
  border-radius: 20px;
}

.mdScroll:hover::-webkit-scrollbar-thumb {
  background: #766640;
}

.mdScroll::-webkit-scrollbar-thumb:hover {
  background: #7b61ff;
}

.mdScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-right: none;
  border-left: none;
}

.mdScroll::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 22px;
}

.mdScroll::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 28px;
}

.close-modal {
  font-size: 28px;
  position: absolute;
  top: -20px;
  right: 20px;
  font-size: 60px;
  color: #e9c8a8;
}
.modal-wrapper .modal-content .modal-body .card {
  width: 100% !important;
  border: 1px solid #fff !important;
  margin-bottom: 20px;
}
.modal-wrapper .modal-content .modal-body .btn {
  background: #fff;
  color: black;
  border-radius: 27px;
  font-weight: bold;
  font-size: 2rem;
  width: 150px;
  margin: 10px auto;
}
.modal-wrapper .modal-content .card:hover {
  box-shadow: 10px 15px 20px rgba(209, 203, 203, 0.6);
  transform: translateY(-8px);
  transition: all 0.3;
}
.modal-wrapper .modal-content .card.selected {
  border: 2px solid red !important;
}
</style>
<style scoped>
.progress-cont .progress__bar {
  height: 9px !important;
}
</style>
